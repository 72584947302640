
@import "../vars";

@keyframes grow {
    from {
        transform: scale(0.1);
        opacity: 0.1;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes regrow {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(0.1);
        opacity: 0.1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
.hidden{
    display: none;
}

.visible{
    display: block;
}
.gq-country-pick-container{
    font-size: 25px;
    color: rgba(255, 255, 255, 0.6);
    vertical-align: middle;
    border-radius: 50%;
    margin-right: 0px;
    width: 22px;
    height: 22px;
    line-height: 15px;
}
.countryPickerDropDown{
 width: 286px;
    background-color: blue;
    height: 386px;
    top: 52px;
    background-color: #21374f;
    /* padding: 11px; */
    position: fixed;
    z-index: 1000000;
    font-size: 14px;
    margin-left: -19px;
    &.createInsight{
        top: 85px;
    }
    &.scoringPage {
         right: 296px;
        top: 135px;
        &::before{
            right: 23px;
            left:auto;
        }
    }
    .gq-cg-search{
           display: INLINE-FLEX;
    /* margin: 24px; */
    padding-left: 10px;
    width: 100%;
        margin-top: 15px;
    border-radius: 3px;
    background: #001a39;
    align-items: center;

    input{
        border: none;
    margin: 0px;
    }
  
    }
      &::before {
    z-index: 999;
    transform: rotate(45deg);
    content: '';
       top: -7px;
    position: absolute;
    left: 23px;
    padding: 7px;
    background-color: #21374f;
  }
    .checkAllContainer{
        display:flex;
        margin-bottom: 3px;

        .checkBox{
             margin-left: 165px;;
    /* right: -98px; */
    margin-top: 5px;
        }

        img{
                width: 16px;
    margin-right: 8px;
    /* margin-bottom: 10px; */
    margin-left: 7px;
        }
    }

.gq-cg-country-list-container{
    position: initial;
    top: 100px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #21374f;
    padding: 16px;
    border-radius: 3px;
    /* -webkit-box-shadow: 0 2px 6px 0 rgba(36, 36, 36, 0.5); */
    box-shadow: none;
}
.gqi-search{
font-size: 17px;
    margin-left: -2px;
    margin-right: -2px;
    margin-top: 5px;
}

.gq-cg-country-list-container li{
        display: inline-block;
    }

.gq-cg-country-list-container 
    ul{
        height: 95%;
    }
    .checkAllContainer i{
    font-size: 18px;
    margin-left: 7px;
    margin-right: 8px;
    }
}
.gq-country-pick-manager {
    @extend .row;
    justify-content: center;
    align-items: flex-start;
    animation: grow .5s;
    &.active {
        & > div {
            animation: grow .5s;
        }
    }
    &:not(.active) {
        animation: regrow .5s;
    }
    .gq-button {
        border-radius: 100%;
        & > i {
            line-height: 0.7em !important;
        }
    }
}

.gq-country-picker {
    @extend .row;
    position: relative;
}

.group-country-sublist {
    @extend .row;
    padding: 8px;
    .flag-icon {
        @extend .rounded-flag;
    }
}

.gq-country-picker-list {
    @extend .row;
    overflow: hidden;
    .flag-container {
        @extend .col;
        border: 2px solid transparent;
        margin: 0;
    }
    .country-flag-name {
        font-size: 8px;
        color: rgba($main-font-color, .65);
        @extend .row;
        justify-content: center;
    }
}

.top-nav-country-list, .top-nav-dimension-list {
    flex-grow: 1;
    align-items: center;
    margin: 0 8px;
    height: 30px;
    overflow-x: hidden;
    overflow-y: hidden;
    &::-webkit-scrollbar {
        background-color: rgba($main-font-color, .1);
        height: 2px;
        border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $dimension-item-active-bg-color;
    }
    &.fixed {
        flex-grow: 0;
    }
}

.top-nav-country-list {
    overflow: hidden;
}

.top-nav-dimension-list {
    position: relative;
    overflow: hidden;
}

.contingent-title {
    color: #fff;
    font-size: 12px;
    border-bottom: 2px solid #fff;
}

.gq-country-picker {
    border-radius: 22px;
    @extend .row;
    align-items: center;
    &.fixed {
        flex-grow: 0;
    }
}

.gq-country-picker-group {
    padding: 0 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    color: rgba($main-font-color, .65);
    transition: color .5s;
    &:hover {
        color: $main-font-color;
    }
    i {
        line-height: 1;
        font-size: 15px;
    }
}
.country-list-dropdown{
    border-bottom: 1px solid  rgba(255, 255, 255, 0.15);
    overflow: auto;
    width: 100%;
    display:flex;
    flex-direction: column;
    height: 300px;
    div{
            margin: 10px;
    display: inline-block;
    width: -webkit-max-content;
    width: -moz-max-content;
    white-space: pre;
    width: min-content;
    }
}
.groups-section-dropdown{
    
    border-bottom: 1px solid  rgba(255, 255, 255, 0.15);
    overflow: hidden;
    width: 100%;
    display:flex;

    div{
       /* margin: 10px; */
    display: inline-block;
    width: -webkit-max-content;
    width: -moz-max-content;
    padding: 16px 14px 11px 14px;
    white-space: pre;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    }
    .selected
    { border-bottom: 1px solid white;
        color:white;
        background-color: transparent;
    }
}
    

.groups-section-dropdown::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3) !important;
    border-radius: 2px;
}


.groups-dropdown {
    padding: 8px 0;
    pointer-events: all;
    background-color: #40536a;
    border-radius: 3px;
}

.groups-section-dropdown .arrow-right{
        position: absolute;
    right: 0px;
    height: 49px;
    background-image: linear-gradient(to left, #21374f, #21374f 31%, rgba(33, 55, 79, 0));
    display: flex;
    align-items: center;
    width: 44px;
    z-index: 1000000000;
    cursor: pointer; 
    .arrow{
     border: 1px solid;
    width: 10px;
    border-width: 0px 0px 1px 1px;
    height: 10px;
    -webkit-transform: rotate(223deg);
    -ms-transform: rotate(223deg);
    transform: rotate(223deg);
    margin-left: 28px;
    }
}
.groups-section-dropdown .arrow-left{
    position: absolute;
    left: 0px;
    height: 49px;
    background-image: linear-gradient(to left, #21374f, #21374f 31%, rgba(33, 55, 79, 0));
    display: flex;
    transform: rotate(180deg);
    align-items: center;
    width: 44px;
    z-index: 1000000000;
    cursor: pointer; 
    .arrow{
     border: 1px solid;
    width: 10px;
    border-width: 0px 0px 1px 1px;
    height: 10px;
    -webkit-transform: rotate(223deg);
    -ms-transform: rotate(223deg);
    transform: rotate(223deg);
    margin-left: 28px;
    }
}

.groups-dropdown-item {
    padding: 4px 12px;
    min-width: 64px;
    font-size: 13px;
    cursor: pointer;
    color: rgba($main-font-color, .65);
    transition: all .5s;
    &.active {
        background-color: rgba(#fff, .1);
        color: $main-font-color;
    }
    &:hover {
        background-color: rgba(#fff, .1);
    }
}

.top-nav-dimension-container {
    position: relative;
    overflow: hidden;
}

.top-nav-country-list-container {
    position: relative;
    overflow: hidden;
}

.hidden-country-group {
    width: 22px;
    height: 22px;
    margin-right: 4px;
    background: rgba(#fffffe, .2);
    border-radius: 50%;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    @extend .row;
    span {
        font-size: 9px;
        color: $main-font-color;
    }
}

.hidden-countries-tooltip {
    @extend .row;
    padding: 8px;
    .flag-icon {
        @extend .rounded-flag;
    }
}

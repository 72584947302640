.gq-title-bar {
  flex-shrink: 0;
  height: 34px;
  overflow: hidden;
  background: #5c6ac0;
  .gq-title-bar-text {
    width: 85px;
    height: 18px;
    // font-family: OpenSans;
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }
}
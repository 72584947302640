.gq-country-button {
  .flag-icon {
    @extend .rounded-flag;
  }
  .flag-container {
    &:hover {
      cursor: pointer;
    }
    &.active {
      border-color: $map-multi-select-active;
    }
    @extend .row;
    align-items: center;
    border-radius: 50%;
    margin: 0 .25em 0 0;
    border-width: $timeline-bottom-flags-border-width;
    border-color: transparent;
    border-style: solid;
  }
}
.login-forgot-password {
  @extend .row;
  justify-content: flex-start;
  width: 100%;
  margin: 8px 0;
  span {
    font-size: 12px;
    color: $black;
  }
  a {
    color: #fff;
  }
}

.login-animation{
    width: 500px;
    display: inline-block;
    @include mobile {
      width: auto;
    }
}
.login-animation-container{
       -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: auto;
    z-index: 1;
    position: relative;
    @include mobile {
      padding-inline: 10vw;
      padding-top: 10vh;
    }
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}
.gq-insights-list {
  border-right: 1px solid rgba(#fff, .15);
  position: relative;
  width: 238px;
}

.gq-insights-list-header {
  height: 52px;
  padding: 0 16px;
  span {
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }
}

.gq-insight-list-edit-mode-button {
  background-color: white !important;
  color: #001937 !important;
  border-color: transparent !important;
  &:hover {
    transform: scale(1.1);
    border-color: white !important;
    background-color: white !important;
  }
  &.active {
    border-color: transparent !important;
    background-color: #f36f67 !important;
    color: #001937 !important;
  }
  &.active:hover {
    transform: scale(1.1);
    border-color: #f36f67 !important;
    background-color: #f36f67 !important;
    color: #001937 !important;
  }
}

.gq-insights-list-content {
  overflow-y: overlay;
  overflow-y: auto;
}
.signals-title {
	display: flex;
	justify-content: center;
	font-size: 1.5rem;
	margin: .5rem 0;
}

.signal-column {
	display: flex;
	justify-content: center;
	font-size: .65rem;
	font-weight: bold;
	color: #ddd;
}

.signal-row {
	display: flex;
	justify-content: space-between;
	min-height: 2rem;
	margin-bottom: 2px;
}

.signal-row-cell {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	margin-inline: 1px;
}

.signals-message {
	padding: 2rem 1rem;
	&.error {
		color: $toast-error-color;
	}
}
$negative-color: #fd3d3c;
$positive-color: #17ff96;
$small-screen: 1500px;
@keyframes pulsate {
  0% {
    transform: scale(.8)
  }
  50% {
    transform: scale(1)
  }
  100% {
    transform: scale(.8)
  }
}

.hidden-transition {
  will-change: fill-opacity;
  transition: fill-opacity .5s ease;
}

.pulsate {
  transform-origin: center;
  transform-box: fill-box;
  animation: pulsate 3s linear infinite .5s;
}

.will-change-circle {
  will-change: r, transform;
}

.map-container {
  .map-mode-toggle {
    position: absolute;
    right: 1em;
    top: 1em;
    width: 13px;
    height: 13px;
    cursor: pointer;
    &.active {
      #risk {
        stroke: $main-selection-color;
      }
    }
  }
  &.anno-mode {
    .host-rect,
    .host-circle {
      cursor: crosshair !important;
    }
    .host-rect {
      transition: all .5s ease;
      &:hover {
        fill: rgba(#fff, .15);

      }
    }
  }
  @extend .stretched;
  overflow: hidden; // D3 classes (not in react render)
  .positive-circle {
    @extend .will-change-circle;
    fill: $map-positive-circle-fill;
  }
  .negative-circle {
    @extend .will-change-circle;
    fill: $map-negative-circle-fill;
  }
  #mapContainerSVG {
    .land {
      fill: $map-mesh-land-color;
      @extend .hidden-transition;
      &.hidden {
        fill-opacity: 0;
      }
    }
    .node {
      * {
        cursor: pointer;
        pointer-events: all;
      }
      .host-circle {
        @extend .will-change-circle;
        transition: r .5s ease, fill .5s ease;
        will-change: r, fill;
        fill: $map-circle-not-selected;
        stroke: none;
        @extend .hidden-transition;
        &.hidden {
          fill-opacity: 0;
          r: 0;
        }
      }
      .host-circle:hover {
        transition: r .5s ease, fill .5s ease;
        will-change: r, fill;
        fill: $map-circle-not-selected-hover;
        stroke: none;
      }
      .circle-text {
        will-change: fill-opacity, stroke-opacity, font-size;
        fill: $map-circle-text-color;
        font-size: $map-circle-text-font;
        text-anchor: middle;
        pointer-events: none;
      }
      .circle-text-mapview {
          font-size: 10px;
          font-weight: 700;
          text-shadow: 1px 1px 3px black;
          @media (max-width: $small-screen) {
            font-size: 12px;
        }
      }

      .circle-text-score {
        pointer-events: none;
        @extend .circle-text;
        fill: $map-circle-text-score-color;
        stroke-width: .5px;
        &.positive-circle {
          fill: $positive-color;
          stroke: none;
        }
        &.negative-circle {
          fill: $negative-color;
          stroke: $negative-color;
        }
      }
      .circle-text-score-mapview {
          text-shadow: 1px 1px 3px black;
          font-size: 9px;
          font-weight: 900;
          @media (max-width: $small-screen) {
            font-size: 12px;
          }
      }

      .score-circle {
        pointer-events: none;
        transition: r .5s ease;
        &.hide {
          display: none;
        }
      }
      .score-circle-halo {
        pointer-events: none;
        transition: r .5s ease .5s;
        &.positive-circle {
          @extend .pulsate;
          fill: rgba($map-positive-circle-fill, .22);
        }
        &.negative-circle {
          @extend .pulsate;
          fill: rgba($map-negative-circle-fill, .22);
        }
        &.hide {
          display: none;
        }
      }
    }
  }
}

.delta-caret {
  stroke-width: .5px;
  &.positive-circle {
    fill: $positive-color;
    stroke: $positive-color;
  }
  &.negative-circle {
    fill: $negative-color;
    stroke: $negative-color;
  }
}

.map-tooltip-container {
  padding: 16px;
  font-size: 10px;
  color: rgba($main-font-color, .7);
  .map-tooltip-header {
    font-weight: bold;
    margin-bottom: 8px;
  }
  .map-tooltip-item {
    padding: 4px 8px;
    margin-bottom: 4px;
    background-color: #20374f;
    border-radius: 3px;
    justify-content: space-between;
  }
   .activeToolTip:hover {
    background-color:  #20374fad;
  }
  .tooltip-dimension {
    flex-grow: 1;
    margin-right: 4px;
  }
  .tooltip-score {
    &.positive {
      color: $positive-score;
    }
    &.negative {
      color: $negative-score;
    }
  }
}

.map-and-chart-container{
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-y: auto;
}


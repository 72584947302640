.row {
    display: flex;
    flex-direction: row;
}

.row-r {
    display: flex;
    flex-direction: row-reverse;
}

.col {
    display: flex;
    flex-direction: column;
}

.stretched {
  width: 100%;
  height: 100%;
}

@mixin fixed-flex($size) {
  flex-basis: $size;
  flex-shrink: 0;
  flex-grow: 0;
}

@mixin underline-transition($duration, $timing) {
  content: "";
  position: absolute;
  background-color: $main-font-color;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  transition: all $duration $timing;
  transform: scaleX(0);
}

.gq-insight-annotation-anchor {
  border-radius: 50%;
  color: white;
  background-color: #00142c;
  border-radius: 20px;
  border: 1px solid white;
  font-size: 13px;
  font-weight: bold;
  opacity: .7 !important;
}

.checkbox-container
{
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;


/* Hide the browser's default checkbox */
input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
    height: 13px;
    border: 1px solid white;
    width: 13px;
    background-color: #eee0;

&.radioButton{
     border-radius: 7px;
}
}
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
    background-color: white;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-container
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container 
input:checked ~ .checkmark:after {
    display: block;
    left: 3px;
    /* top: 5px; */
    position: absolute;
    width: 5px;
    height: 9px;
    border: 1px solid black;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkbox-container 
input:checked ~ .radioButton:after{
    display: none !important;
}

.gq-search{
    display: INLINE-FLEX;
    /* margin: 24px; */
    padding-left: 10px;
    width: 100%;
    /* border: 1px solid white; */
    margin-top: 15px;
    margin-bottom: 10px;
    border-radius: 3px;
    background: #001a39;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.gq-search input{
    border: none !important;
    margin: 0px;
}
.gq-toast-container {
  margin-top: 27px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.gq-toast {
  color: black;
  font-size: 15px;
  border-radius: 3px;
  padding: 4px 8px;
  margin: 8px 0;
  width: 400px;

  .gq-toast-buttons{ .gq-button{
    border: 1px solid black;
    color: black;
  }
  .gq-button:hover{
    border: 1px solid white;
    font-weight: 600;
  }
}

  .gq-toast-close-button .gq-button{
    color:black;
  }

  &.info {
    background-color: #4760ee;
  }
  &.error {
    background-color: #f36f67;
  }
  &.success {
    background-color: #17ff96;
    color: black;
  }
  .gq-toast-close-button {
    margin-left: 10px;
  }
}

.gq-toast-buttons {
  &>* {
    margin-left: 8px;
    &:last-child {
      margin-right: 8px;
    }
  }
  
}
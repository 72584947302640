.gq-top-events-item
{
     -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 3px;
    -webkit-transition: background .3s ease-in;
    transition: background .3s ease-in;
    padding: 4px 8px;
    padding-top: 6px;
    padding-right: 8px;
    height: fit-content;
    padding-bottom: 4px;
    padding-left: 8px;
    cursor: pointer;
    /* height: 24px; */
    margin: 2px 0;
    font-size: 12px;
    //cursor: pointer;
}
.gq-top-events-item .flag-icon{
    align-self: flex-start;
    margin-top: 4px;
}
.gq-top-events-item:hover{
background-color: rgba(255, 255, 255, 0.3);
cursor: pointer;
}

.hidden{
    display:none;
}

.correct{
    font-size: 13px;
    padding-top: 2px;   
    .bold{
        font-weight: 700;
    }
}

.gq-top-events-container {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding-bottom: 10px;
    flex-shrink: 0;
    margin: 16px 16px 0;
    width: 285px;
    overflow: hidden;
    .collapse-button {
        transition: transform .5s ease;
        &.collapsed {
            transform: rotate(180deg);
            position: relative;
        }
    }
}

.gq-top-events-title {
    color: white;
    font-size: 13px;
    @extend .row;
    align-items: center;
}

.gq-top-events-content {
    min-height: 10px;

    overflow: hidden;
    transition: all .5s ease;
    &.collapsed {
        min-height: 0;
        height: 0;
    }
}

.gq-top-events-dimension {
    flex-grow: 1;
    margin: 0 8px;
    color: rgba($main-font-color, .65);
}

.gq-top-events-score {
    &.positive {
        color: $positive-score;
    }
    &.negative {
        color: $negative-score;
    }
}

.gq-menu {
  pointer-events: all;
  padding: 12px 0;
}

.gq-menu-item {
  color: inherit;
  text-decoration: none;
  padding: 8px;
  height: 28px;
  min-width: 132px;
  font-size: 13px;
  cursor: pointer;
  i {
    line-height: 0.8;
  }
  &:hover,
  &.selected {
      background-color: rgba(#fff, .15);
      color: white;
  }
  &.disabled {
    color: rgba(#fff, .1);
    cursor: default;
  }
}

.gq-menu-item-icon {
  margin: 0 9px;
  min-width: 16px;
  flex-shrink: 0;
}

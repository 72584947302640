$cr-positive: #00fcb8;
$cr-negative: #ff3f3f;

.contingent-risk-container {
    @extend .col;
}

.contingent-risk-row {
    font-size: 13px;
    height: 50px;
    @extend .row;
    align-items: center;
    & > div {
        margin: 0 4px;
    }
    &.axis-row {
        border-top: 1px solid rgba(#fff, .15);
        margin-left: 20px;
        height: auto;
    }
}

.cr-flag-col {
    width: 20px;
}
.cr-country-name-col {
    color: rgba($main-font-color, .65);
    width: 100px;
    flex-shrink: 0;
}

.cr-graph-col {
    flex-grow: 1;
    path {
        stroke-opacity: 0;
    }
    .tick {
        line {
            stroke-opacity: 0;
        }
        text {
            fill: rgba($main-font-color, .65);
        }
    }
}

.contingent-bar {
    fill: $main-font-color;
    transition: stroke .5s ease;
    &.positive {
        fill: $cr-positive;
    }
    &.negative {
        fill: $cr-negative;
    }
    &.active {
        fill: $main-font-color;
        stroke: $main-font-color;
        stroke-width: 2px;
    }
}

.cr-score-today {
    width: 30px;
    flex-shrink: 0;
    color: $main-font-color;
    &.positive {
        color: $cr-positive;
    }
    &.negative {
        color: $cr-negative;
    }
}
.contingent-risk-no-data {
    color: rgba($main-font-color, .65);
    font-size: 13px;
}

.contingent-risk-hover-bar {
    pointer-events: none;
    stroke: $main-font-color;
    stroke-width: 1;
    stroke-dasharray: 1, 5;
    stroke-dashoffset: 2.5;
    stroke-linecap: round;
}

.contingent-risk-tooltip {
    &.positive {
        color: $cr-positive;
    }
    &.negative {
        color: $cr-negative;
    }
    font-size: 10px;
    min-width: 16px;
    padding: 8px 4px;
}
.gq-confirm-slider {
  position: absolute;
  background: #f36f67;
  padding: 8px;
  z-index: 9999;
  &.clickable {
    cursor: pointer;
  }
  &.horizontal {
    width: 100%;
  }
  &.vertical {
    height: 100%;
  }
  &.right {
    right: 0;
    top: 0;
  }
  &.left {
    left: 0;
    top: 0;
  }
  &.top {
    top: 0;
    left: 0;
  }
  &.bottom {
    bottom: 0;
    left: 0;
  }
}

@import "../mixins";
@import "../vars";

.gq-terms-container {
    @extend .col;
    justify-content: center;
    align-items: center;
    background-color: rgba(40, 61, 87, 0.2);
    height: 100%;
}
.gq-terms-buttons{
  width: 100%;
  position: relative;
    .auth-form-common__submit-loader{ 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    }
    .spinner{
    margin: 9px auto;
    width: 70px;
    text-align: center;
    }
}

.gq-terms-header{
    font-weight: normal;
    font-size: 21px;
}

.gq-terms-content {
    align-items: center;
    border-radius: 4px;
    max-width: 600px;
    max-height: 450px;
    margin: 16px 0;
    overflow-y: auto;
    background-color: #1a304a;
    z-index: 100;


}

.gq-terms-paragraph {
    margin: 32px 16px;
}

.gq-terms-paragraph-header {
    margin-bottom: 4px;
}

.gq-terms-paragraph-content {
    font-size: 13px;
}

.gq-terms-filler {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
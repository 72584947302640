// Color Scheme
$app-main-color: #001328;
$main-font-color: #fff;
$main-selection-color: #fff;
$secondary-bottom-gradient: rgba(0, 19, 40, 0.8);
$main-border-color: rgba(255, 255, 255, .15);
$event-footer-date-color: rgba(255,255,255, 0.6);
$divider-border-color: #979797;
$dimension-item-base-color: $main-font-color;
$dimension-item-active-bg-color: rgba($dimension-item-base-color, .35);
$dimension-item-tiers-bg:
    rgba($dimension-item-base-color, .25)
    rgba($dimension-item-base-color, .2)
    rgba($dimension-item-base-color, .15)
    rgba($dimension-item-base-color, .1)
    rgba($dimension-item-base-color, .1)
    rgba($dimension-item-base-color, .1);
$dimension-item-tiers-hover:
    rgba($dimension-item-base-color, .3)
    rgba($dimension-item-base-color, .25)
    rgba($dimension-item-base-color, .2)
    rgba($dimension-item-base-color, .15)
    rgba($dimension-item-base-color, .15)
    rgba($dimension-item-base-color, .15);
$dimension-header-icon-color: rgba(255,255,255, .3);
$event-score-box-color: rgba(#e2e0ed, .3);
$article-date-font-color: rgba(255, 255, 255, 0.6);
$article-text-font-color: #e2e0ed;
$article-score-form-bg: rgba(255,255,255, .1);
$article-score-form-header-color: #e2e0ed;
$custom-drop-down-bg: rgba(38, 58, 83, .9);
$custom-drop-down-color: unquote($string: "#ffffffb3");
$toast-color: $main-font-color;
$toast-error-color: #fc4a43;
$toast-success-color: #24D084;
$top-risk-bar-item-bg: #20374f;
$map-mesh-land-color: rgba(113, 145, 184, .07);
$map-negative-circle-fill: #ff3f3f;
$map-positive-circle-fill: #00fcb8;
$map-circle-text-color: $main-font-color;
$map-circle-text-score-color: ($main-font-color, .65);
$map-circle-text-score-color-mapview: #ccc;
$map-circle-not-selected: rgba(48, 71, 97, 0.71);
$map-circle-not-selected-hover: rgba(48, 71, 97, 1);
$map-multi-select-active: rgba(190, 190, 190, 0.81);
$timeline-chart-bg: rgba(0,26,58,0.5);
$timeline-chart-bg-expanded: rgba(0,26,58,1);
$timeline-chart-preview-tab-border: rgba(255, 255, 255, .65);
$timeline-button-bg: rgba(255, 255, 255, 0.13);
$timeline-button-color: white;
$timeline-event-dot-stroke: rgb(0, 26, 58);
$timeline-graph-line-width: 2;
$timeline-bottom-flags-border-color: rgba(0,0,0,0);
$terms-dimmer-bg: rgb(0, 19, 40);
$tutorial-blurred-background: rgba(40, 61, 87, 0.7);
$general-container-bg: #33475e;
$general-popup-bg: #21374f;
$insight-section-bg: #334760;
$general-sub-container-bg: #21374f;
$positive-score: #17ff96;
$negative-score: #fd3d3c;

// Fonts
$event-menu-button-font-size: 11px;
$event-item-font-size: 13px;
$event-item-scored-box-font-size: 8px;
$event-item-line-height: 1.31;
$event-item-lines-to-show: 2;
$article-header-font-regular: 28px;
$article-header-font-s: 18px;
$article-date-font-size: 13px;
$article-link-font-size: 10px;
$article-text-font-regular: 15px;
$article-text-font-s: 12px;
$article-score-form-header-font-size: 13px;
$article-slider-font-size: 10px;
$article-button-font-s: 12px;
$activity-page-font: 11px;
$feedback-text-area-font: 12px;
$top-risk-bar-title-font: 10px;
$map-circle-text-font: 12px;

// Containers
$left-block-width: 312px;
$right-block-width: 306px;
$border-width: 1px;

// Others
$flag-width: 16px;
$timeline-bottom-flags-border-width: 2px;

// Responsiveness
$desktop-width: 1024px;
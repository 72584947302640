@import "./map";
@import "./picker";
@import "./menu";
.gq-cg-container {
  position: relative;
  width: 100%;
  @extend .row;
}

.gq-cg-content-wrapper {
  position: relative;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

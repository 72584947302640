.gq-insight-component-list-container {
  width: 100%;
  height: 100px;
  border-top: 1px solid rgba(#fff, .15);
}

.gq-insight-component-list {
  overflow-x: scroll;
  overflow-y: hidden;
  min-height: 80px;
}

.gq-component-list-item-thumbnail {
  position: relative;
  border-radius: 3px;
  border: .5px solid #5d7188;
  height: 64px;
  width: 115px;
  min-height: 64px;
  min-width: 115px;
  background-color: $app-main-color;
  img {
    object-fit: fill;
    width: 99%;
    height: 99%;
    cursor: pointer;
  }
  &.editing {
    border: 1px solid white;
  }
}

.gq-component-list-item-name-container {
  height: 24px;
  flex-shrink: 1;
  span {
    font-size: 12px;
    color: rgba(#fff, .65);
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    line-height: 2;
  }
  input {
    font-size: 12px;
    color: white;
    background: none;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
  }
  &:not(.editing) {
    cursor: text;
    .gq-component-list-item-name-edit {
      max-width: 85%;
    }
  }
}

.gq-component-list-item-container {
  max-width: 115px;
  margin: 8px;
  display: inline;
}

.gq-component-list-item-name-edit {
  height: 100%;
  max-width: 100%;
}

.gq-component-list-item-name-edit-icon {
  font-size: 12px;
  flex-shrink: 0;
  color: rgba(#fff, .65);
  margin: 4px 0 0 4px;
}

.gq-component-list-item-thumbnail-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 99%;
  height: 99%;
  z-index: 999;
  cursor: pointer;
  background-color: $app-main-color;
  .gq-component-list-item-thumbnail-menu {
    position: absolute;
    right: 0;
    top: 0;
    margin: 4px;
  }
}

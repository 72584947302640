$disabled-color: rgba($main-font-color, .2);
@keyframes button-click {
  0% {
    color: rgba($main-font-color, .6)
  }
  50% {
    color: $main-selection-color
  }
  100% {
    color: rgba($main-font-color, .6)
  }
}

.gq-button-container {
  position: relative;
}

.gq-button {
  @extend .row;
  align-items: center;
  justify-content: center;
  transition: all .3s ease;
  cursor: pointer;
  padding: 4px 8px;
  font-size: inherit;
  border: 1px solid rgba($main-font-color, .4);
  color: rgba($main-font-color, .4);
  border-radius: 3px;
  &.disabled {
    cursor: default;
    color: $disabled-color;
    &:not(.no-border):not(.active) {
      border-color: $disabled-color;
    }
  }
  &:not(.active):not(.disabled):hover {
    color: rgba($main-font-color, .6);
  }
  &:not(.no-border):not(.active):not(.disabled):hover {
    color: #001931;
    background-color: $main-font-color;
  }
  &.active {
    color: $main-selection-color;
    border-color: $main-selection-color;
  }
  &.no-border {
    border: none;
    &:not(.active):not(.disabled):hover {
      color: rgba($main-font-color, .6);
      background: none;
    }
    &.active {
      border: none;
    }
  }
  &.no-padding {
    padding: 0;
  }
  &.catch-all-pointer-events{
    pointer-events: all;
  }
  &.tab-left {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &.tab-right {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &.tab-top {
    border-bottom: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &.tab-bottom {
    border-top: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &>i {
    font-size: inherit;
    line-height: 0.9em;
  }
  &.round {
    padding: 0;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
  }
  &.square {
    padding: 4px 4px;
  }
  &.animate:not(.toggle) {
    animation: button-click .3s linear;
  }
  &.loading {
    min-height: 2em;
    width: 100%;
  }
  &.fluid {
    width: 100%;
    height: 100%;
  }
}

.gq-button-tooltip-container {
  @extend .row;
  font-size: 12px;
  z-index: 999;
  pointer-events: none;
  &.clickable {
    pointer-events: all;
  }
}

.gq-button-tooltip {
  @extend .row;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 rgba(36, 36, 36, 0.5);
  font-size: 12px;
  z-index: 1000;
  position: fixed;
  background-color: $general-popup-bg;
  color: rgba($main-font-color, .65);
  margin: .5rem 0;
  padding: .5rem;
  &::after {
    z-index: 100000;
    transform: rotate(45deg);
    content: '';
    position: absolute;
    padding: 5px;
    background-color: $general-popup-bg;
  }
  &.no-padding {
    padding: 0;
  }
  &.bottom::after {
    top: -0.25rem;
  }
  &.left {
    &::after {
      right: 10px;
    }
    &.center::after {
      right: -4px;
    }

    &.end {
      &::after {
        right: 3px;
      }  
    }
  }
  &.right {
    &.center::after {
      left: -4px;
    }
    &::after {
      left: 10px;
    }
    &.end {
      &::after {
        left: 3px;
      }  
    }    
  }
  &.top::after {
    bottom: -.25rem;
  }
  &:not(.right):not(.left).center::after {
    left: calc(50% - 5px);
  }
  &:not(.top):not(.bottom).center::after {
    top: calc(50% - 5px);
  }
  &.no-diamond {
    &::after {
      display: none;
    }
  }
}

.gq-button-caption {
  line-height: 1;
  white-space: nowrap;
}

.gq-button-loading-container {
  border-radius: 4px;
  background-color: rgba(#000, .6);
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  z-index: 2;
  min-height: 2em;
  border: 1px solid white;
  @extend .row;
  align-items: center;
}

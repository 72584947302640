.bordered-section {
  border-top: 1px solid rgba(#fff, .15);
  border-bottom: 1px solid rgba(#fff, .15);
}

.gq-create-activity {
  background-color: $app-main-color;
  width: 100%;
  height: 100%;
  @extend .row;
}

.insight-container {
  position: relative;
  border-left: 1px solid rgba(#fff, .15);
  height: 100%;
  width: 25%;
  max-width: 442px;
  min-width: 250px;
  @extend .col;

  &:last-child {
    margin-bottom: 0 !important;
  }
}

.gq-insight-container {
  position: relative;
  border-left: 1px solid rgba(#fff, .15);
  height: 100%;
  min-width: 500px;
  width: 25%;
  max-width: 25%;
  padding: 16px;
  @extend .col;

  &:last-child {
    margin-bottom: 0 !important;
  }

  .gq-inshight-header {
    flex-shrink: 0;

    .gq-header-section {
      margin-bottom: 8px;

      .title {
        font-size: 15px;
      }

      .gq-button-save-active {
        color: #fff !important;
        border-color: #fff !important;

        &:hover {
          color: #001931 !important;
        }
      }
    }
  }

  .gq-insight-input {
    height: 32px;
    margin-bottom: 16px;
  }

  .gq-insight-editor {
    flex-grow: 1;
    flex-shrink: 1;
    .gq-editor-toolbar {
      margin-bottom: 16px;
      flex-shrink: 0;
    }

    div[data-slate-editor] {
      width: 100%;
      max-height: 100%;
      overflow-y: auto;
      flex-grow:1;
      flex-shrink: 1;
      flex-basis: 0;
    }
  }

}

.insight-risk-picker-container {
  position: absolute;
  left: 100%;
  width: 400px;
  height: 100%;
  z-index: 999;

  #sidebar-risk-picker-wrapper {
    width: 100%;

    #sidebar-risk-picker-wrapper-header {
      .gq-button-container {
        .gqi-arrow-right.icon, .gqi-arrow-left.icon{
          display: none;
        }    
      }
    }
  }
}

.insight-risk-picker-expand-button {
  position: absolute;
  top: 1em;
  left: -2em;
}

.side-padded-32 {
  padding: 0 32px;
  margin-bottom: 16px;
}

.insight-header {
  flex-shrink: 0;

  .header-section {
    margin-bottom: 8px;

    .title {
      font-size: 15px;
    }

    .insight-icon {
      font-size: 20px;
    }

    &:first-child {
      margin: 32px 0 16px 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.gq-insight-components {
  flex-shrink: 0;
}

.editor-section {
  border-top: .5px solid rgba(#fff, .15);
  border-bottom: .5px solid rgba(#fff, .15);
  height: 0;

  .insight-text-controls {
    height: 100%;
    align-items: center;

    span {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 14px;
      margin: 0 4px;
      color: $black;
    }
  }
}

.insight-editor-container {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
}

.insight-edit-text-area {
  // overflow: scroll;
  // width: 100%;// height: 100%;
  padding: 8px;

  textarea {
    width: 100%;
    resize: none;
    font-size: 15px;
    color: $black;
    background: none;
    border: none;
    outline: none;
    line-height: 1.5;

    &::placeholder {
      color: rgba(#fff, .4);
    }
  }
}

.insight-country-list {
  @extend .row;

  .flag {
    margin: 2px;
  }
}

.gq-insight-content {
  @extend .col;
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  &.heatmap {
    align-items: flex-start;
    & .heatmap-container {
      overflow-y: auto;
    }
  }
}

.gq-insight-loaded-data {
  max-width: 768px;
  min-width: 768px;
  overflow: hidden;
  box-shadow: -7px 7px 30px 0px rgba(0, 0, 0, 0.6);
  border: 1px solid #0f1229; // &.headless {
  //   max-width: unset;
  //   overflow: initial;
  // }
}

.gq-insights-edit-area {
  position: relative;
  @extend .row;
  align-items: center;

  &.editing {
    cursor: crosshair;
  }

  &>img {
    pointer-events: none;
    width: 750px;
    margin: 20px 8px;
  }
}

.gq-resize-caret {
  position: absolute;
  bottom: 0;
  right: 22px;
  width: 0;
  height: 10px;
  font-size: 25px;
  transform: rotate(-45deg);
  cursor: nwse-resize;
}

.gq-insight-edit-header {
  height: 50px;
  background-color: #0f1229;
  width: 100%;
  padding: 0 8px;
  align-items: center;
  @extend .row;

  .gq-insight-dim-name {
    height: 18px;
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 0;
  }

  .insight-country-list {
    padding: 0 8px;
    overflow: hidden;
    @extend .row;
    flex-grow: 1;
  }

  .gq-insight-date-range {
    height: 17px;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $black;
    flex-shrink: 0;
  }
}

.insight-divider {
  height: 1px;
  background-color: rgba(#fff, .15);
}

.gq-textarea {
  background: none;
  color: #fff;
  resize: none;
  border: none;
  padding: 8px;
  margin: 0;
  outline: none;

  &:focus {
    background-color: inherit;
    border: none;
  }

  &::placeholder {
    color: rgba(#fff, .4);
  }
}

.gq-annotation-point {
  background-color: #fff;
  cursor: pointer;
  cursor: -webkit-grab;

  &.circle {
    border-radius: 50%;
  }
}

.gq-annotation-text {
  min-height: 0;
  outline: none;
  border-style: hidden;
  resize: none;
  margin: 0;
  cursor: pointer;
  cursor: -webkit-grab;
  user-select: none; // position: absolute;
  top: 0;
  left: 0;
  background: none;
  color: $black;
  font-size: 13px;
  padding: 8px;
  border: 1px solid transparent;
  width: 100%;
  resize: none;

  &::selection {
    background: rgba(#fff, .1);
    color: $black;
  }

  &::-moz-selection {
    background: rgba(#fff, .1);
    color: $black;
  }

  &:focus:not(.active) {
    border: 1px solid transparent;
  }

  &.active {
    // cursor: move;
    border-color: #fff;
    user-select: none;
  }

  &.editing {
    cursor: text;
    border-style: dashed;
    user-select: text;
  }

  &[readonly] {
    background-color: initial;
    cursor: initial;
  }
}

.gq-anno-link {
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
}

.gq-anno-link {
  pointer-events: none;
}

.gq-insight-editor-date-tooltip {
  padding: 8px;
  font-size: 13px;
}

#flashCover {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  background: rgba(255,255,255, .8);
  border: 1px solid #001328;
}

.gq-insight-editor-img-node {
  transition: transform .3s ease;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.6);
  margin: 8px 0;
  transform: scale(0.98);

  &>img {
    border: 1px solid transparent;
  }

  &:hover {
    cursor: zoom-in;
    transform: scale(1);
    &>img {
      border: 1px solid white;
    }
  }
}

.gq-map-note-anchor {
  circle {
    fill: white;
  }

  text {
    font-size: 13px;
    stroke: #00142c;
    text-anchor: middle;
  }
}

.gq-editor-link {
  color: #9dabff !important;
}

.gq-editor-button-main {
  font-size: 24px;
  position: absolute;
  left: -18px;
  bottom: 130px;
}

.gq-editor-button-secondary {
  font-size: 24px;
  position: absolute;
  left: -18px;
  bottom: 180px;
}

.gq-cancel-button-container {
  font-size: 16px;
  left: -12px;
}

.gq-snapshot-button {
  color: #00142c !important;
  background: #fff !important;
  border-color: transparent !important;

  &:hover {
    color: #00142c !important;
    border-color: transparent !important;
    background: #f36f67 !important;
  }
}

.gq-upload-button {
  color: rgba(#fff, .4) !important;
  border-color: rgba(#fff, .4) !important;
  background: #00142c !important;

  &:hover {
    color: #00142c !important;
    border-color: transparent !important;
    background: #f36f67 !important;
  }
}

.gq-cancel-button {
  color: #00142c !important;
  border-color: #f36f67 !important;
  background: #f36f67 !important;

  &:hover {
    color: #00142c !important;
    border-color: #f36f67 !important;
    background: #f36f67 !important;
    transform: scale(1.1);
  }
}

.gq-commit-button {
  color: #00142c !important;
  border-color: #17ff96 !important;
  background: #17ff96 !important;

  &:hover {
    color: #00142c !important;
    border-color: #17ff96 !important;
    background: #17ff96 !important;
    transform: scale(1.1);
  }
}

.gq-full-image {
  border: 3px solid white;
  display: inline-block;
  vertical-align: middle;
  max-height: 100%;
  width: auto;
  object-fit: contain;
}

.gq-accordion {
  height: 100%;
  @extend .row;
  align-items: center;
  transition: background .5s ease;
  &.expanded {
    background-color: rgba(#fff, .1);
  }
  span {
    @extend .row;
  }
}
@import "../vars";
@import "./contingentRisk";

.gq-country-page-container {
    font-size: 13px;
    max-width: 550px;
    margin: 32px auto;
}

.gq-country-page-header {
    font-size: 23px;
    color: $main-font-color;
    font-weight: bold;
    margin-bottom: 32px;
    @extend .row;
    justify-content: space-between;
    align-items: center;
    .flag-icon {
        width: 22px;
        line-height: 1.65;
    }
}

.gq-country-page-section  {
    margin-top: 16px;
}

.gq-country-page-section-header {
    font-size: 13px;
    font-weight: bold;
    color: $main-font-color;
    margin-bottom: 16px;
    @extend .row;
    align-items: center;
    & > span {
        margin-right: 16px;
    }
}

.gq-country-page-section-content {
    padding: 16px;
    border-radius: 3px;
    border: 1px solid rgba(#fff, .15);
}

.gq-country-page-header-separator {
    width: 80%;
    position: absolute;
    left: 0;
    bottom: 0;
    border-bottom: 1px solid rgba(#fff, .15);
}
.gq-top-movements-content {
    min-height: 50px;
    @extend .row;
    flex-wrap: wrap;
    color: rgba($main-font-color, .7);
}

.gq-top-movements-header {
    margin-bottom: 16px;
    color: $main-font-color;
}

.top-movement-item {
    height: 21px;
    padding: 4px 8px;
    margin: 0 4px 4px 0;
    background-color: #20374f;
    border-radius: 3px;
    justify-content: space-between;
}
.top-movement-dimension {
    flex-grow: 1;
    margin-right: 4px;
}
.top-movement-score {
    &.positive {
        color: $positive-score;
    }
    &.negative {
        color: $negative-score;
    }
}

.gq-country-tile-wrapper {
    padding: 8px 16px;
    background-color: rgba(#fff, .13);
    height: 30px;
    margin: 4px;
    border-radius: 3px;
}

.gq-country-tile {
    @extend .row;
    align-items: center;
    font-size: 13px;
}

.gq-country-tile-line {
    width: 13px;
    height: 2px;
}

.gq-country-tile-name {
    color: rgba($main-font-color, .7);
    margin: 0 8px;
}


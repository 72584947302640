@import "../vars";
@import "../mixins";

.content {
    display: flex;
    flex: 1 1 100%;
    flex-wrap: nowrap;
    overflow: hidden;
}

.preload-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
}

.preload-error-logo {
    width: 200px;
    margin-bottom: 32px;
}

.map-provider {
    padding-top: 10px;
    flex: 1;
    width: 100%;
    overflow-y: hidden;
    height: 0;
}

.mobile-page-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.gq-chip {
  height: 22px;
  border-radius: 1px;
  border-color: transparent;
  border-bottom-width: 2px;
  background: transparent;
  padding: 2px 4px 4px 4px;
  font-size: 12px;
  border-bottom-style: solid;
  color: white;
  margin-right: 8px;
  white-space: nowrap;
  #dimentionList > &:last-child,
  span:last-child > & {
    margin-right: 0;
  }
}

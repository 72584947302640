@import "../mixins";
@import "../vars";

#sidebar-risk-picker-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 100%;
    margin-left: 5px;
    padding-bottom: 10px;
    opacity: 0.85;
    background-color: $app-main-color;
    color: $main-font-color;
    transition: all .5s ease;
    &.minimized {
        max-width: 20px;
        #sidebar-risk-picker-content {
            opacity: 0.2;
        }
    }
}

#sidebar-risk-picker-wrapper-header {
    font-size: 13px;
    text-align: left;
    color: rgba($main-font-color, .7);
    display: flex;
    margin-top: 16px;
    margin-right: 8px;

    .sidebar-risk-picker-wrapper-header-content {
        display: flex;
        flex-grow: 1;
        align-items: center;
        .header-tabs {
            display: flex;
            margin-left: auto;
            font-size: 16px;
        }    

    }
}

#sidebar-risk-picker-content {
    flex-grow: 1;
    transition: all .5s ease;
    padding-right: 16px;
    max-height: 100%;
    overflow-y: auto;

    .sidebar-risk-picker-content-list {
        display: flex;
        flex-direction: column;
        @include fixed-flex(210px);
        height: 100%;
        padding-bottom: 60px;
        .dimensions-item {
            background-color: transparent;
            flex-grow: 1;
            flex-shrink: 1;
            padding: 0 8px;
            max-height: 85px;
            span {
                font-size: 13px;
            }
            i {
                font-size: 26px;
                padding: 14px 14px 10px 10px;
                @media screen and (max-height: 1000px) {
                    font-size: 14px;
                    padding: 10px;
                }
            }    
            &.top-divider {
                border-top: 2px solid $dimension-item-active-bg-color;
            }
            &.active {
                background-color: $dimension-item-active-bg-color;
                font-weight: 400;
                color: $main-selection-color;
            }
            &:hover {
                background-color: $dimension-item-active-bg-color;
            }
        }    
    }

    .sidebar-risk-picker-content-tree {
        height: 100%;
        .dimensions-item {
            border-radius: 3px;

            &.vertical {
                text-align: center;
                overflow: hidden;
                justify-content: center;
            }
        }
    }
}



.dimensions-item {
    font-size: 11px;
    @extend .row;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    background: none;
    width: 100%;
    color: rgba($main-font-color, .65);
    border-radius: 3px;
    transition: background-color 300ms ease-out;
    @for $i from 1 through length($dimension-item-tiers-bg) {
        $tier-color: nth($dimension-item-tiers-bg, $i);
        $tier-hover-color: nth($dimension-item-tiers-hover, $i);
        &.tier-#{$i - 1} {
            background-color: $tier-color;
            &:hover {
                background-color: $tier-hover-color;
            }
        }
    }
    &.disabled {
        cursor: not-allowed;
    }
    &.active {
        background-color: $dimension-item-active-bg-color;
        font-weight: bold;
        color: $main-selection-color;
        &:hover {
            background-color: $dimension-item-active-bg-color;
        }
    }
    .rotate-text {
        transform: rotate(-90deg);
    }

}

@charset "UTF-8";

@font-face {
    font-family: "geoquant";
    src: url("../../assets/fonts/geoquant.eot");
    src: url("../../assets/fonts/geoquant.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/geoquant.woff") format("woff"),
        url("../../assets/fonts/geoquant.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-weight: normal;
    font-style: normal;
}

[data-icon]:before {
    font-family: "geoquant" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="gqi-"]:before,
[class*="gqi-"]:before {
    font-family: "geoquant" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.gqi-clear:before {
    content: "\e000";
}
.gqi-log:before {
    content: "\e001";
}
.gqi-close:before {
    content: "\e002";
}
.gqi-mini:before {
    content: "\e003";
}
.gqi-money:before {
    content: "\e004";
}
.gqi-csv:before {
    content: "\e005";
}
.gqi-dimensions:before {
    content: "\e006";
}
.gqi-expand:before {
    content: "\e009";
}
.gqi-user:before {
    content: "\e00a";
}
.gqi-world:before {
    content: "\e00b";
}
.gqi-feedback:before {
    content: "\e00c";
}
.gqi-flag:before {
    content: "\e00d";
}
.gqi-graph:before {
    content: "\e02b";
}
.gqi-graph-line:before {
    content: "\e00f";
}
.gqi-graph-scatter:before {
    content: "\e010";
}
.gqi-guide:before {
    content: "\e011";
}
.gqi-indicators:before {
    content: "\e012";
}
.gqi-down:before {
    content: "\e013";
}
.gqi-delete:before {
    content: "\e014";
}
.gqi-dropdown:before {
    content: "\e015";
}
.gqi-bookmark:before {
    content: "\e016";
}
.gqi-csv-1:before {
    content: "\e005";
}
.gqi-warning:before {
    content: "\e018";
}
.gqi-hamburger:before {
    content: "\e019";
}
.gqi-png:before {
    content: "\e01a";
}
.gqi-left:before {
    content: "\e01b";
}
.gqi-restore:before {
    content: "\e01c";
}
.gqi-right:before {
    content: "\e01d";
}
.gqi-up:before {
    content: "\e01f";
}
.gqi-delta:before {
    content: "\e020";
}
.gqi-settings:before {
    content: "\e021";
}
.gqi-edit:before {
    content: "\e022";
}
.gqi-risk:before {
    content: "\e023";
}
.gqi-add:before {
    content: "\e024";
}
.gqi-x:before {
    content: "\e025";
}
.gqi-active:before {
    content: "\e026";
}
.gqi-broadcast:before {
    content: "\e027";
}
.gqi-contingent:before {
    content: "\e028";
}
.gqi-country-data:before {
    content: "\e029";
}
.gqi-event:before {
    content: "\e02a";
}
.gqi-heatmap:before {
    content: "\e03d1";
}
.gqi-graph-1:before {
    content: "\e02b";
}
.gqi-history:before {
    content: "\e02c";
}
.gqi-info:before {
    content: "\e02d";
}
.gqi-money-1:before {
    content: "\e004";
}
.gqi-new-insight:before {
    content: "\e02f";
}
.gqi-pdf:before {
    content: "\e030";
}
.gqi-restore-1:before {
    content: "\e01c";
}
.gqi-weight:before {
    content: "\e032";
}
.gqi-share:before {
    content: "\e033";
}
.gqi-dates:before {
    content: "\e034";
}
.gqi-download:before {
    content: "\e035";
}
.gqi-tags:before {
    content: "\e036";
}
.gqi-gq:before {
    content: "\e037";
}
.gqi-gq-1:before {
    content: "\e037";
}
.gqi-pdf-1:before {
    content: "\e030";
}
.gqi-share-active:before {
    content: "\e033";
}
.gqi-csv-2:before {
    content: "\e005";
}
.gqi-share-1:before {
    content: "\e033";
}
.gqi-zip:before {
    content: "\e03d";
}
.gqi-power:before {
    content: "\e040";
}
.gqi-plus-circle:before {
    content: "\e024";
}
.gqi-check-circle:before {
    content: "\e041";
}
.gqi-check:before {
    content: "\e041";
}
.gqi-ellipsis-v:before {
    content: "\e042";
}
.gqi-arrow-left:before {
    content: "\e01b";
}
.gqi-arrow-right:before {
    content: "\e01d";
}
.gqi-search:before {
    content: "\e01e";
}
.gqi-upload:before {
    content: "\e049";
}
.gqi-camera-1:before {
    content: "\e04a";
}
.gqi-lines:before {
    content: "\e00f";
}
.gqi-bold:before {
    content: "\e02e";
}
.gqi-bullet-point:before {
    content: "\e04b";
}
.gqi-italic:before {
    content: "\e04d";
}
.gqi-elipsis:before {
    content: "\e042";
}
.gqi-hyperlink:before {
    content: "\e017";
}
.gqi-checkmark:before {
    content: "\e048";
}
.gqi-upload-1:before {
    content: "\e049";
}
.gqi-alert:before {
    content: "\e018";
}
.gqi-virtual-today:before {
    content: "\e051";
}
.gqi-scatter:before {
    content: "\e03e";
}
.gqi-oil:before {
    content: "\e052";
}
.gqi-dimension19:before {
    content: "\E199";
}
.gqi-dimension20:before,
.gqi-contingent-risk37:before {
    content: "\E112";
}
.gqi-dimension18:before {
    content: "\E119";
}
.gqi-dimension17:before {
    content: "\E121";
}
.gqi-dimension16:before {
    content: "\E117";
}
.gqi-dimension15:before,
.gqi-contingent-risk31:before {
    content: "\e122";
}
.gqi-dimension11:before {
    content: "\e113";
}
.gqi-dimension13:before {
    content: "\e120";
}
.gqi-dimension12:before {
    content: "\e118";
}
.gqi-dimension21:before {
    content: "\e114";
}
.gqi-dimension10:before {
    content: "\e115";
}
.gqi-dimension14:before {
    content: "\e038";
}
.gqi-dimension22:before {
    content: "\E198";
}
.gqi-contingent-risk29:before {
    content: "\e039";
}

.gqi-contingent-risk24:before {
    content: "\e004";
}

.gqi-political:before {
    content: "\E198";
}
.gqi-expropriation-1:before {
    content: "\E199";
}
.gqi-selected:before {
    content: "\ee10";
}
.gqi-unselected:before {
    content: "\ee11";
}
.gqi-lock:before {
    content: "\e11d";
}
.gqi-activate:before {
    content: "\ed01";
}
.gqi-lens:before {
    content: "\e008";
}
.gqi-slide:before {
    content: "\e010";
}
.gqi-certified:before {
    content: "\e03a";
}
.gqi-globe:before {
  content: "\e03b";
}
.gqi-email:before {
  content: "\e03c";
}
.gqi-goto:before {
  content: "\e03d";
}

.gqi-size:before {
  content: "\e03f";
}

.auth-form-common {
  @extend .col;
  justify-content: center;
  margin: auto;
  width: 260px;
  z-index: 1;
  position: relative;
}

.auth-form-common__form {
  @extend .col;
  align-items: center;
  position: relative;
}

.auth-form-common__field input {
  user-select: auto;
}

.auth-form-common__title {
  font-size: 21px;
  text-align: center;
  color: #fff;
  margin-bottom: 38px;
}

.auth-form-common__field {
  margin-bottom: 6px;
}

.input-container {
  position: relative;
  width: 100%;

  input {
    border: none;
    width: 100%;
    height: 44px;
    font-size: 15px;
    line-height: normal;
    letter-spacing: normal;
    color: $black;
    background-color: #1a304a;
    border-radius: 3px;
    padding: 8px 16px;
    outline: none;
    &::placeholder {
      color: $black;
    }
  }
}

.auth-form-common__submit {
  width: 100%;
  margin-top: 32px;
  position: relative;
  button {
    width: 100%;
    height: 44px;
    background: none;
    border: 1px solid #fff;
    color: #fff;
    cursor: pointer;
    outline: none;
    font-size: 15px;
    border-radius: 3px;
    &:hover {
      background: #fff;
      color: #1a304a;
    }
  }
  .auth-form-common__submit-loader {
    @extend .row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.auth-form-common__error {
  margin-top: 32px;
  span {
    font-size: 15px;
    color: tomato;
  }
}

.form-field-tooltip {
  position: absolute;
  top: 10px;
  right: 12px;
  padding-top: 3px;
  cursor: pointer;

  &:hover > .form-field-tooltip__text {
    display: block;
  }

  i {
    opacity: .65;
  }
}

.form-field-tooltip__text {
  display: none;
  position: absolute;
  top: 32px;
  left: -10px;
  background-color: #40536a;
  padding: 10px 12px;
  font-size: 12px;
  width: 210px;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 rgba(36, 36, 36, 0.5);
  z-index: 3;
}

@import "../vars.scss";
@import "../mixins";

.navbar {
    width: 100%;
    display: flex;
    height: 52px;
    align-items: center;
    justify-content: flex-start;
    border-bottom: solid $border-width $main-border-color;
    &.impersonate {
        background-color: blue;
    }
}

.navbar-logo {
    @extend .row;
    cursor: pointer;
    align-items: center;
    flex-shrink: 0;
    height: 37.5px;
    margin: 4px 0 6.5px 32px;
    font-size: 18px;
    letter-spacing: 1px;
    width: 295px;

    @include mobile {
        margin: 0;
    }

    & > img {
        width: 150px;
        height: auto;
    }
    & > span:first-child {
        font-weight: bold;
    }
}

.rounded-flag {
    width: 16px;
    height: 16px;
    margin: 0;
    border: 1px solid rgba(48, 71, 97, 0.81);
    background-clip: content-box;
    border-radius: 50%;
}

.top-nav-buttons {
    align-items: center;
}

.top-nav-view {
    width: 100%;
    height: 100%;
    justify-content: center;
}



.top-nav-dimension, .top-nav-country-name {
    text-transform: uppercase;
    font-size: 13px;
    color: #fff;
    font-weight: bold;
}

.navbar-navigation {
    @extend .row;
    @include fixed-flex(306px);
    align-items: center;
    padding: 0 16px;
    height: 100%;
    @media (min-width: 1920px) {
        @include fixed-flex(400px);
    }
}

.top-nav-date-picker {
    align-items: center;
    font-size: 12px;
    color: $black;
    margin-right: 63px;
    .top-nav-date {
        position: absolute;
        margin: 0 4px;
        margin: 0 4px;
    font-size: 14px;
    /* border-left: 1px solid #ccc8c870; */
    font-weight: 600;
    color: white;
    }
}

.top-nav-country {
    align-items: center;
}

.navbar-pickers {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    width: 100%;
    height: 100%;
}

.top-nav-country-flag {
    margin-right: 8px;
    margin-bottom: 5px;
    .flag-icon {
        @extend .rounded-flag;
    }
    .empty-flag {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: rgba(#fffffe, .2);
    }
}

.gq-navbar-vertical-separator {
    height: 24px;
    width: 1px;
    background-color: #1a314d;
    &.marginSides{
        margin: 0px 10px;
    }

}

.gq-navbar-settings-menu {
    pointer-events: all;
    padding: 8px 0;
    color: $black;
    font-size: 13px;
    box-shadow: 0 1px 11px 1px rgba(0,0,0,0.25);
    .gq-navbar-settings-menu-item {
        height: 28px;
        cursor: pointer;
        transition: all .5s ease;
        padding: 0 8px;
        display: flex;
        align-items: center;
        &:hover {
            background-color: rgba(#fff, .15);
        }
    }
    .gq-navbar-settings-menu-text {
        height: 28px;
        cursor: default;
        padding: 0 8px;
        display: flex;
        align-items: center;
        font-style: italic;
        font-size: 11px;
        text-transform: lowercase;
    }
    .gq-navbar-settings-menu-separator {
        height: 1px;
        width: 100%;
        margin: 8px 0;
        background-color: rgba(#fff, .15);
    }
}

.navbar-country-picker-container {
    position: absolute;
    left: -20px;
    top: 37px;
    z-index: 10000000;
}


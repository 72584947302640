
@import "../vars";

.gq-cw-delete-confirm {
    @extend .row;
    justify-content: center;
    align-items: center;
    width: 0;
    height: 100%;
    background: #f36f67;
    color: #fff;
    font-size: 13px;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
    & span {
        pointer-events: none;
        margin: 0 auto;
        z-index: 1;
    }
}
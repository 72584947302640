.gq-cg-map {
  width: 100%;
  height: 100%;
  position: relative;
}

.cg-map-item {
  cursor: pointer;
  stroke: #000d1b;
  stroke-width: 0.7px;
  fill: #21374f;
  transition: fill .3s ease;
  &.active {
    fill: #fff;
  }
  &.no-edit {
    cursor: default;
  }
}

.gq-cg-dot-group {
  cursor: pointer;
  &.hide {
    .gq-cg-dot {
      stroke: transparent;
      fill: transparent;
    }
    .gq-cg-dot-pulse {
      opacity: 0;
    }
  }
  &.no-edit {
    cursor: default;
  }
}

.gq-cg-dot {
  stroke: #fff;
  fill: #fff;
}

@keyframes dot-pulse {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

.gq-cg-dot-pulse {
  stroke: none;
  fill: rgba(#fff, .2);
  animation: dot-pulse 3s infinite;
}

.gq-cg-bg-dimmer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100px;
  transform: rotate(180deg);
  background-color:rgba(0, 25, 56, 0.5);
}

$error-color: #f36f67;
.gq-feedback {
  width: 458px;
  padding: 16px !important;
  pointer-events: all;
  input,
  textarea,
  .gq-feedback-type {
    background-color: #1a304a;
    outline: rgba(#fff, .4);
    border-radius: 3px;
    border: none;
  }
  input,
  textarea {
    width: 100%;
    height: 100%;
    color: $black;
    &.error,
    &.error:focus {
      border: 1px solid $error-color;
      color: $error-color;
    }
  }
  textarea:focus {
    border: inherit;
    box-shadow: inherit;
    background-color: #1a304a;
  }
  input {
    padding: 0 8px;
  }
  .gq-feedback-type {
    cursor: pointer;
  }
  .gq-feedback-type-icon {
    line-height: 1;
    margin-left: 8px;
  }
  .gq-feedback-header-icon {
    i {
      font-size: 16px;
    }
  }
  .gq-feedback-header-close {
    i {
      font-size: 15px;
    }
  }
  .gq-feedback-header-title {
    color: #fff;
    font-size: 15px;
  }
  .gq-feedback-title {
    height: 28px;
    font-size: 13px;
  }
}

.gq-feedback-body-text {
  font-size: 13px;
  padding: 8px;
  resize: none;
  border-radius: 3px;
  min-height: 150px;
}

.gq-feedback-dropdown-tooltip {
  pointer-events: all;
  margin: 8px 0;
  min-width: 75px;
  .gq-feedback-dropdown-tooltip-item {
    cursor: pointer;
    height: 28px;
    color: $black;
    background: none;
    padding: 0 8px;
    &:hover {
      background: rgba(#fff, .15);
      color: #fff;
    }
  }
}

.gq-msg-row {
  .error {
    color: $error-color;
  }
  .sucess {
    color: #17ff96;
  }
}
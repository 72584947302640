.gq-modal-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  visibility: hidden;
  &.visible {
    visibility: visible;
  }
}

.gq-modal-cover {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: $app-main-color;
  opacity: 0.8;
  pointer-events: none;
}

.gq-modal-content {
  padding: 3em;
  width: 100%;
  height: 100%;
  // position: absolute;
  z-index: 1001;
  // opacity: 0;
}

.navbar {
    &.mobile {
        flex: 0 0 52px;
    }
}

.navbar-mobile {
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid $border-width $main-border-color;
    padding-inline: 1rem;
    flex-shrink: 0;
}

$hamburger-padding-inline: 4px;
$hamburget-top: 40px;
.navbar-mobile-hamburger {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 700;
    left: 100vw;
    z-index: 999999999;
    background-color: $app-main-color;
    position: fixed;
    top: $hamburget-top;
    width: calc(100% - #{$hamburger-padding-inline * 2});
    height: calc(100vh - #{$hamburget-top});
    flex-direction: column;
    padding-top: 25px;
    transition: left .3s ease-in,
    visibility .3s ease-in;
    border-radius: .5rem;
    &.visible {
        left: 4px;
        box-shadow: 0px 6px 16px -8px rgba(255, 255, 255, 0.04),
         0px 9px 28px 0px rgba(255, 255, 255, 0.08),
         0px 12px 48px 16px rgba(255, 255, 255, 0.05);
    }
}

.navbar-mobile-logo {
    &-img {
        width: 150px;
        height: auto;
    }
}

.mobile-hamburger-link {
    font-size: 2rem;
    color: inherit;
    text-decoration: none;
    padding-block: .5rem;
    width: 100%;
    text-align: center;
}

.navbar-mobile-toggle {
    &-img {
        height: 24px;
    }

}

.auth-page-container {
    @extend .app;


    &__animation {
        position: fixed;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        z-index: 0;
    }

    &__logo {
        position: absolute;
        top: 40px;
        left: 40px;
        height: 60px;
    }
}

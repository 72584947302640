.score-article-container {
    width: 263px;
    height: auto;
    padding: 16px;
    opacity: 0.76;
    border-radius: 5px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.24);
    background-color: #07213f;
    //justify-content: center;
    display: flex;
    flex-direction: column;
}

.score-article-container .divider {
    width: 100% !important;
    border-color: #21374f !important;
}

.score-article-button {
    width: 231px;
    height: 44px;
    border-radius: 3px;
    color: white;
    border: 1px solid;
    outline: none;
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    cursor: pointer;
    background-color: #ffffff;
    color: #21374f;
}

.score-article-button:disabled {
    background-color: transparent;
    color: #ffffff;
}

// .score-article-button:hover{
//   width: 231px;
//   height: 44px;
//   border-radius: 3px;
//   background-color: #ffffff;
//   color: #21374f;
// }

.score-countries-container {
    display: flex;
    flex-direction: column;

    .flag-icon {
        cursor: pointer;
    }
}

.score-countries-header {
    display: flex;
}

.score-countries-title {
    font-size: 12px;
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    flex-grow: 1;
    color: rgba(255, 255, 255, 0.65);
}

.score-countries-see-all {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.65);
}

.score-select-country-flag {
    width: 21px !important;
    height: 20px !important;
    border-radius: 50%;
    -webkit-background-size: cover !important;
    background-size: cover !important;
    margin-right: 9px !important;
}

.country-selected-mode,
.select-country-mode {
    display: flex;
    padding: 5px 10px 5px 10px;
    //overflow: hidden;
    opacity: 1;
    transition: opacity 1s ease-out;
    height: auto;
    flex-wrap: wrap;
    justify-content: flex-start;
    line-height: 30px;
    //div{
    // width: 35px;
    //}
}

.country-selected-mode {
    //         &.fadeOutAnimation{
    //  opacity: 0;
    //  padding: 0px;
    //  margin: 0px;
    //  height: 0px;
    //     }
    //          &.fadeInAnimation{
    //  opacity: 1;
    //  padding: 0px;
    //  margin: 0px;
    //   scale: 1.1;
    //     }
}

.select-country-mode {
    transition: opacity 1s ease-out;
    //height: 27px;
    //  &.fadeOutAnimation{
    //  opacity: 0;
    //  padding: 0px;
    //  margin: 0px;
    //   height: 0px;
    //     }
    //      &.fadeInAnimation{
    //  opacity: 1;
    //  padding: 0px;
    //  margin: 0px;
    //     }
}

.country-selected-mode .score-select-country-flag {
    margin-right: 10px !important;
    margin-top: 6px !important;
}

.country-name {
    margin-top: 3px;
}

.map-flag-name {
    fill: #fff;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    margin-left: -50%;
}

.score-risks-container {
    display: flex;
    flex-direction: column;
}

.score-risks-header {
    display: flex;
}

.score-risks-title {
    font-size: 12px;
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    flex-grow: 1;
    color: rgba(255, 255, 255, 0.65);
}

.score-risks-see-all {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.65);
}

.select-risk-mode,
.risk-selected-mode {
    display: flex;
    flex-direction: column;
    padding: 5px 0px 5px 0px;
    transition: 0.3s ease-out;
}

.select-risk-mode {
    opacity: 1;
    height: auto;

    &.active {
        display: flex;

        .score-risk-item {
            opacity: 1;
            transition: 0.3s ease-out;
            height: 27px;

            &.selected-risk {
                background-color: white;
                font-weight: 600;
                color: #001938;
            }
        }
    }
}

.risk-selected-mode {
    opacity: 1;

    &.fadeAnimation {
        height: 0px;
        display: none;

        .score-risk-item {
            opacity: 0;
            transition: 0.3s linear;
            height: 0px;

            &.selected-risk {
                background-color: white !important;
                background-image: white !important;
                color: black;
            }
        }
    }

    &.active {
        display: flex;

        .score-risk-item {
            opacity: 1;
            transition: 0.3s linear;
            height: 27px;
        }
    }
}

.score-risk-item {
    font-size: 11px;
    position: relative;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    background: none;
    width: 100%;
    margin: 1px;
    border-radius: 0.2em;
    color: rgba(255, 255, 255, 0.65);
    -webkit-transition: background-color 300ms ease-out;
    width: 100%;
    height: 27px;
    padding: 6px;
    /* opacity: 0.1; */
    border-radius: 3px;
    background-color: #21374f;
    transition: background-color 300ms ease-out;
}

.score-slider-data-container {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.score-slider-data-header {
    display: flex;
}

.score-slider-data-title {
    font-size: 12px;
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    flex-grow: 1;
    color: rgba(255, 255, 255, 0.65);
}

.score-slider-item {
}

.color-slider-bar {
    background-image: linear-gradient(
        to left,
        #ff3838,
        #ffffff 51%,
        #d2ffe2 67%,
        #bbfebe 82%,
        #00ff90
    );
    height: 3px;
    margin: 15px 0;
    width: 100%;
}

.white-slider-bar {
    background-image: linear-gradient(
        to left,
        rgb(255, 255, 255),
        rgba(255, 255, 255, 0.2)
    );
    height: 3px;
    margin: 15px 0;
    width: 100%;
}

.score-slider-value-text {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.65);
}

.all-risks-container {
    position: fixed;
    top: 192px;
    right: 132px;
    z-index: 999;
    border-radius: 3px;
    padding: 17px;
    padding-top: 2px;
    background-color: #22374f;
    width: 259px;
    /* overflow-y: auto; */
    height: 397px;
    /* overflow-x: hidden; */

    .score-risk-item {
        background-color: rgb(55, 75, 96);
    }
}

.all-risks-container::before {
    /* z-index: 10000000000000; */
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    content: '';
    position: absolute;
    padding: 7px;
    z-index: 999;
    background-color: #22374f;
    right: 12px;
    top: -4px;
}

.risks-list {
    width: 100%;
    overflow-y: auto;
    height: 307px;
}

.risks-list .score-risk-item {
    width: 90%;
}

.past-score-item {
    display: flex;
    border-radius: 3px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.24);
    background-color: #07213f;
    flex-wrap: wrap;
    padding: 3px;
    opacity: 1;
    height: 25px;
    transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -webkit-transition: opacity 0.25s ease-in-out;

    &.opacity {
        opacity: 0.2;
    }
}

.past-score-menu {
    i:visited {
        color: rgb(23, 255, 150);
    }
}

.past-score-item {
    .past-score-property {
        width: 29%;
        color: rgba(255, 255, 255, 0.65);
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
    }

    .flag-icon {
        width: auto;
    }
}

.past-score-container {
    position: relative;
    margin-top: 15px;
    margin-bottom: 25px;
}

.past-score-list {
    margin-bottom: 10px;
}

.past-score-header {
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
}

.past-score-item-container {
    position: relative;
}

.past-score-item-container:hover .edit-score-menu-icon {
    visibility: visible;
    width: 20px;
}

.edit-score-menu-icon {
    position: absolute;
    right: 0;
    font-size: 21px;
    width: 20px;
    visibility: hidden;
}

.score-item-menu {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    @extend .row;
    justify-content: center;
    align-items: center;
}

.hideMenu {
    display: none;
}

.past-score-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    top: 0px;
    height: 100%;
    width: 100%;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    /* height: 100%; */
    z-index: 10000000;
    /* background-color: rgba(158, 158, 158, 0.13); */
    position: absolute;

    .gq-button {
        padding-right: 10px;
    }
}

.newScore {
    top: -200px;
    opacity: 0;
    position: relative;
    transition: 0.4s ease-out;
}

.newScore {
    &.active {
        top: 0px;
        opacity: 1;
        position: relative;
    }
}

.score-countries-toggle-picker-button {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.score-countries-country-picker-container {
    position: absolute;
    right: -20px;
    z-index: 9999999;
    top: 37px;
}

.gq-cg-menu-item {
  width: 100%;
  color: rgba($main-font-color, .65);
  font-size: 13px;
  height: 34px;
  padding-top: 2px;
  padding-left: 2px;
  cursor: pointer;
  @extend .row;
  align-items: center;
  transition: color .5s ease, background-color .5s ease;
  &:hover {
    color: $main-font-color;
    .menu-delete-icon {
      opacity: 1;
      transform: scale(1);
    }
  }
  &.editing {
    color: $main-font-color;
    background-color: rgba(#fff, .2);
  }
}
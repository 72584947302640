.gq-generic-popup {
  background: #21374f;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 rgba(36, 36, 36, 0.5);
  width: 500px;
  padding: 16px 0;
}

.gq-generic-popup-top {
  padding: 0 16px;
}

.gq-generic-popup-exit-button {
  font-size: 16px;
}

.gq-generic-popup-content-icon {
  margin-right: 20px;
  font-size: 32px;
  color: #f36f67;
}

.gq-generic-popup-title {
  h3 {
    font-size: 17px;
    color: white;
    font-weight: bold;
    line-height: 1.41;
    margin: 0;
  }
}

.gq-generic-popup-text {
  p {
    white-space: pre-wrap;
    font-size: 15px;
    color: rgba(#fff, .65);
    margin: 0;
  }
}

.gq-generic-popup-buttons {
  margin-top: 32px;
  border-top: 1px solid rgba(#fff, .1);
  padding-top: 16px;
  &>* {
    margin-left: 8px;
    &:last-child {
      margin-right: 8px;
    }
  }
}
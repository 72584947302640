$active-color: #fff;
$inactive-color: rgba(#fff, .4);
.gq-input {
  width: 200px;
  border-bottom: 1px solid $inactive-color;
  padding: 8px 0;
  input {
    width: 100%;
    font-size: 13px;
    color: #fff;
    background: none;
    outline: none;
    border: none;
    line-height: 1em;
    &::placeholder {
      color: rgba(#fff, .4);
    }
    margin: 0;
    padding: 0 8px;
    height: auto;
    &:focus {
      border: none;
    }
  }
  &.full-width {
    width: 100%;
  }
  &.active {
    border-bottom: 1px solid $active-color;
  }
  &.no-border {
    border-bottom: 1px solid transparent;
  }
  &.with-tags {
    display: flex;
    flex-wrap: wrap;
    min-height: 44px;
    input {
      flex: 1 1 100px;
    }
  }
}

.gq-tag {
  border-radius: 11px;
  height: 22px;
  color: rgba(#fff, .65);
  padding: 2px 8px;
  font-size: 12px;
  margin: 2px;
  background: #40536a;
  cursor: pointer;
  &>span {
    line-height: 1;
    vertical-align: middle;
  }
  &:hover {
    color: white;
  }
  &.error {
    background: #ff6161;
  }
}

.gq-tag-icon {
  vertical-align: middle;
  font-size: 18px !important;
  width: 6px !important;
  height: 15px !important;
}

/* Important to prevent yellow autofill inputs in chrome */ 
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected,
input:-internal-autofill-previewed {
  background-color: #1a304a !important;
  color: #A6AFB9 !important;
  -webkit-text-fill-color: #A6AFB9 !important;
  transition: background-color 999999s ease-in-out 0s,
    color 999999s ease-in-out 0s;
}

.has-error {
  box-shadow: 0px 2px 0px 0px #f36f67;
}

.input-error-message {
  font-size: 12px;
  color: #fff;
  opacity: 0.65;
}
@import "./customWeightsDelete";
@import "./customWeightsSlider";
$active-border: 3px solid white;

.gq-custom-weights {
  flex-grow: 1;
  @extend .row;
  width: 100%;
}

.gq-cw-content {
  flex: 1;
  margin: 0 auto;
  padding: 0 35px;
  padding-top: 6px;
  width: 100%;
}

.gq-cw-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}

.gq-cw-header {
  @extend .row; // justify-content: space-between;
  align-items: center;
  font-size: 13px;
  height: 32px;
  margin: 26px 0 26px 0;

  .gq-input {
    width: 210px;
  }
}

.gq-cw-header-buttons {
  @extend .row;
  font-size: 13px;
}

.gq-cw-menu-container {
  width: 230px;
  border-right: 1px solid #21374f;
  height: 100%;
  flex-shrink: 0;
}

.cw-active,
.selected {
  background-color: rgba(255, 255, 255, 0.2);
}

.cw-no-cw-message {
  height: 75vh;
  display: flex;
  margin-left: -230px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 300;
  font-size: 21px;
  color: #a6afb9;
}

.gq-cw-menu-header {
  padding: 15px 10px 15px 11px;
  @extend .row;
  justify-content: space-between;
  align-items: center;
}

.gq-cw-menu-header-button {
  font-size: 24px;
  background-color: rgba(255, 255, 255, 0.6);
  vertical-align: middle;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-top: 2px;
  margin-right: 4px;
}

.gq-cw-menu-header-title {
  line-height: 2;

  &>span {
    color: $main-font-color;
    font-size: 15px;
  }
}

.gq-cw-menu-item {
  position: relative;
  height: 35px;
  padding-left: 10px;
  cursor: pointer;
  @extend .row;
  flex-grow: 1;
  align-items: center;

  .active-icon,
  .inactive-icon {
    font-size: 15px;
    padding-right: 12px;
  }

  .gq-cw-menu-item-caption {
    line-height: 1.5;
    font-size: 13px;
    color: $black;
    transition: color .5s ease;
  }

  .gq-cw-menu-item-subs {
    padding-left: 40px;
  }

  &.editing {
    .gq-cw-menu-item-caption {
      color: #fff;
    }
  }

  &:hover {
    .gq-cw-menu-item-caption {
      color: #fff;
    }

    .menu-delete-icon {
      &>div {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  .spinner {
    &>div {
      width: 6px;
      height: 6px;
    }
  }
}

.gq-cw-accordion {
  @extend .col;

  &:first-child {
    position: relative;

    &::after {
      content: "";
      background: #21374f;
      position: absolute;
      bottom: 0;
      left: 9px;
      height: 1px;
      width: 209px;
    }

    &::before {
      content: "";
      background: #21374f;
      position: absolute;
      top: 0;
      left: 9px;
      height: 1px;
      width: 209px;
    }
  }

  .gq-cw-accordion-header {
    padding-left: 12px;
    padding-right: 12px;
    @extend .row;
    align-items: center;

    &:hover {
      background-color: rgba(#fff, .1);
    }

    &.active {
      background-color: rgba(#fff, .2);
    }
  }

  .gq-cw-accordion-subs {
    @extend .col;
    padding-left: 28px;
  }

  .gq-cw-accordion-expand-state {
    width: 10px;
    height: 10px;
    margin: 0 6px;
    @extend .row;
    align-items: center;
    justify-content: center;
    // transition: transform .5s ease;
    // transform: rotate(-90deg);
  }

  &.expanded {
    .gq-cw-accordion-expand-state {
      transform: rotate(0deg);
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.menu-delete-icon {
  margin-right: 7px;
  font-size: 15px;
  will-change: transform, opacity;
  min-width: 18px;
  min-height: 15px;
  @extend .row;

  &>div {
    opacity: 0;
    transform: scale(0);
    transition: opacity .5s ease, transform .5s;
  }
}

.gq-cw-menu-item-caption {
  flex-grow: 1;
  max-width: 129px;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.5;
}

.gq-cw-overview {
  &>.cw-dim-root {
    &>.cw-dim-tile {
      align-items: center;
    }
  }

  &.sub {
    padding-top: 32px;
    border-top: 1px solid rgba(#fff, .15);
  }
}

.cw-modal-content {
  background-color: #40536a;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 rgba(36, 36, 36, 0.5);
  @extend .col;
  font-size: 15px;
}

.cw-modal-message {
  @extend .row;
  color: #fff;
  margin: 16px auto;
}

.cw-modal-buttons {
  @extend .row;
  font-size: 13px;
  margin: 16px 40px;

  &>div:first-child {
    margin-right: 24px;
  }
}

.cw-dim-root {
  @extend .col;
  align-items: center;
  justify-content: space-between;
  transition: transform 1s ease, width .1s easeOut !important;

  &.tier-0 {
    &>.cw-dim-tile {
      font-weight: bold;
      height: 42px;
      font-size: 16px;
      pointer-events: none;
    }
  }

  &.tier-1 {
    min-width: 20px;

    &>.cw-dim-tile {
      font-weight: bold;
      height: 80px;
    }
  }

  &.tier-2 {
    &>.cw-dim-tile {
      height: 56px;
    }
  }

  &.tier-3 {
    &>.cw-dim-tile {
      overflow: visible;
      height: 24px;
    }
  }
}

.cw-dim-tile {
  font-size: 13px;
  position: relative;
  overflow: hidden;
  @extend .col;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
  transition: transform 1s ease, width .1s ease-out, opacity .5s ease !important;
  cursor: pointer;
  width: 100%;

  .tile-ghost {
    transition: background .5s ease-out;
    position: absolute;
    width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    background-color: transparent;
  }

  &>div:not(.cw-dim-handle):not(.last-tier) {
    overflow: hidden;
    text-overflow: ellipsis;
    margin: -1px 8px;
    white-space: nowrap;
    max-width: 90%;

    &:first-child {
      margin-bottom: 0px;
    }
  }

  &:not(.active).hovered {
    .tile-ghost {
      background-color: rgba(#fff, .25);
    }

    z-index: 1;
  }

  &.active {
    font-weight: bold;

    &.left {
      border-left: $active-border;
      border-top: $active-border;
      border-bottom: $active-border;
    }

    &.center {
      border-top: $active-border;
      border-bottom: $active-border;
    }

    &.right {
      border-right: $active-border;
      border-top: $active-border;
      border-bottom: $active-border;
    }
  }
}

.cw-dim-name {
  &.last-tier {
    overflow: hidden;
    position: absolute;
    top: 50px;
    right: calc(50% - 16px);
    transform: rotate(-45deg);
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 70px;
    color: rgba($main-font-color, .65);
  }
}

.cw-dim-children {
  width: 100%;
  @extend .row;
  justify-content: center;
}

.gq-cw-slider-container {
  margin-top: 100px;
  margin-bottom: 32px;
}

.gq-cw-info-bar {
  font-size: 13px;
  color: $black;
  margin-bottom: 24px;
  width: 100%;
  background-color: rgba(#fff, .2);
  height: 34px;
  padding: 0 8px;
  @extend .row;
  align-items: center;

  &>.gq-button-container {
    font-size: 12px;

    & i {
      line-height: 11px;
    }
  }

  &>i {
    font-size: 17px;
  }
}

.gq-cw-info-caption {
  flex-grow: 1;
  @extend .row;
  align-items: center;
  height: 18px;
}

.gq-cw-picker {
  height: 33px;
  margin-left: 8px;
  @extend .row;
  flex-grow: 1;
}

@media (min-width: 1281px) {
  .gq-cw-content {
    max-width: 1400px;
  }
}

.gq-cw-menu-items {
  max-height: 100%;
  overflow-y: auto;
  padding-bottom: 32px;
}
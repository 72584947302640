$grid-header-size: 23px;
$grid-cell-gap: 0px;
$grid-row-title-width: 160px;
$grid-row-title-width-medium: 140px;
$grid-row-title-width-small: 70px;
$small-screen-max-width: 1350px;
$medium-screen-max-width: 1500px;
$columns-headers-height: 150px;

.heatmap-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-wrap: nowrap;
    flex: 1;
    margin: 32px;
    @media (max-width: $small-screen-max-width) {
        margin: 0;
    }
}

.heatmap-grid-and-legend-container {
    flex: 1;
    display: flex;
    overflow-y: hidden;
}
.heatmap-grid-loading{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 999;
    transform: translate(-50%, 50%);
}

.heatmap-grid {
    display: grid;
    grid-gap: $grid-cell-gap;
    padding-right: 10px;
}

.heatmap-row-header {
    font-size: 14px;
    grid-column: 1;
    max-height: $grid-header-size;
    white-space: nowrap;
    padding-left: 8px;
    max-width: $grid-row-title-width;
    width: $grid-row-title-width;
    display: flex;
    align-items: center;
    cursor: pointer;

    @media (max-width: $medium-screen-max-width) {
        max-width: $grid-row-title-width-medium;
        width: $grid-row-title-width-medium;
    }

    @media (max-width: $small-screen-max-width) {
        max-width: $grid-row-title-width-small;
        width: $grid-row-title-width-small;
    }

    &-hovered,
    &-selected {
        border-radius: $grid-header-size;
    }
    &-hovered {
        background-color: $general-popup-bg;
    }
    &-selected {
        background-color: #fff;
        color: $general-popup-bg;
        font-weight: bold;
    }
}

.heatmap-row-header-text {
    text-overflow: ellipsis;
    overflow-x: hidden;
}

.heatmap-row-header,
.heatmap-legend-label,
.heatmap-tree-element-text,
.heatmap-legend-cell-text {
    color: rgba(#fff, 0.65);
    &-hovered {
        color: #fff;
    }
    &-selected {
        color: $general-popup-bg;
    }
}

.heatmap-legend-container {
    margin: 0 32px;
    @media (max-width: $small-screen-max-width) {
        margin: 0 16px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.heatmap-cell {
    &-selected {
        border: 1px solid #aaa;
    }
}

.heatmap-cell-circle {
    border-radius: 2px;
    width: 100%;
    height: 100%;
}

.heatmap-cell-text {
    width: 46px;
    min-width: 5ch; // makes '0%' be the same size as '100%'
    font-size: 13px;
    font-weight: bold;
    color: #001938;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.heatmap-legend {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.heatmap-legend-label {
    font-size: 13px;
    text-align: right;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    white-space: nowrap;
}

.heatmap-legend-list-container {
    width: 70px;
    display: flex;
    align-items: center;
}

.heatmap-legend-list-item {
    cursor: pointer;
    &:not(:last-child) {
        margin-bottom: $grid-cell-gap;
    }
    display: flex;
    align-items: flex-end;
    padding: 2px 4px;
    &-selected {
        border-radius: 4px;
        background-color: #425469;
    }
}

.heatmap-tooltip-container {
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.heatmap-tooltip-header {
    display: flex;
}
.heatmap-tooltip-right-side {
    margin-left: 5px;
}

.heatmap-tooltip-country,
.heatmap-tooltip-dimension,
.heatmap-tooltip-button {
    color: $main-font-color;
    white-space: nowrap;
}

.heatmap-tooltip-country {
    opacity: 0.9;
    font-size: 12px;
    font-weight: bold;
    margin-left: 4px;
}

.heatmap-tooltip-dimension {
    font-size: 12px;
    font-weight: normal;
    line-height: 1.18;
    margin-top: .25rem;
    margin-bottom: 16px;
}

.heatmap-header-items {
    position: relative;
    height: $columns-headers-height;
    margin-left: $grid-row-title-width;

    @media (max-width: $medium-screen-max-width) {
        margin-left: $grid-row-title-width-medium;
    }

    @media (max-width: $small-screen-max-width) {
        margin-left: $grid-row-title-width-small;
    }
}

.heatmap-header-items-inner {
    position: absolute;
    top: 0;
    left: 86px; //todo: fix calculation
    transform: rotate(-45deg) skew(45deg);
    height: $columns-headers-height;
    width: 140px;
}

.heatmap-tree-element {
    outline: 2px solid $app-main-color;
}

.heatmap-tree-element-text-container {
    height: 15px;
    line-height: 15px;
}
.heatmap-tree-element-text {
    transform: skew(-45deg);
    font-size: 12px;
    padding: 0 8px;
}

.heatmap-tree-element-children {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.heatmap-legend-cell-text {
    font-size: 13px;
    margin-left: 6px;
    &-selected {
        font-weight: bold;
        color: white;
    }
}

.heatmap-header {
    position: relative;
}

.heatmap-toggles-container {
    margin-bottom: 64px;
}

.heatmap-toggle {
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.heatmap-header-select-risk-label {
    font-size: 14px;
    line-height: 1.08;
    color: #667588;
    margin-bottom: 2px;
}
.heatmap-header-risk-types {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.risk-header-item {
    cursor: pointer;
    height: 12.5px;
    display: flex;
    align-items: center;
    font-size: 10px;
    padding-left: 8px;
    margin-top: 2.3px;

    &-selected {
        background-color: #fff;
        color: $general-popup-bg;
        font-weight: bold;
    }

    &-inner {
        transform: skew(-45deg);
    }
}

.focus-header-item {
    height: 20.5px;
    margin-top: 3px;
}

.heatmap-grid-container {
    overflow-y: auto;
}
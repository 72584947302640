
@import "../vars";

.gq-cw-slider {
    width: 100%;
    height: 60px;
    & > div {
        display: inline-block;
    }
    .cw-dim-tile {
        display: inline-block;
        overflow: visible;
        cursor: default;
        position: relative;
        min-width: 20px;
        height: 50px;
        margin-top: 10px;
        padding-top: 10px;
    }
}

.cw-dim-handle {
    position: absolute;
    top: -20px;
    right: -12px;
    cursor: col-resize; 
    & > .icon {
        font-size: 24px;
    }
}
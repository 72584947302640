@import "./mobileInsights";
@import "./mobileNavbar";
@import "./mobileSignals";

.mobile-content {
    height: 100%;
    display: flex;
    .events-toggle-expand {
        display: none;
    }
    #sidebar-risk-picker-wrapper,
    .events {
        display: none;
    }
    .react-datepicker__day--disabled {
        opacity: .3;
    }
}


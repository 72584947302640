.tutorial-box-container{
    border-radius: 3px;
  box-shadow: 0 2px 6px 0 rgba(36, 36, 36, 0.5);
  background-color: #4760ee;
  position: fixed;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.58;
  letter-spacing: normal;
  color: #ffffff;
  padding: 14px;
  cursor: default;
  z-index: 100;
&.show{
     visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
  }

  &.hide{
       visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
  }

&.image-tutorial{
    display: flex;
        align-items: center;
}
    &.show-arrow::before {
    z-index: 999;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    content: '';
    position: absolute;
    padding: 7px;
    background-color: #4760ee;;
    }
    &.show-arrow{
        &.top-left::before{
             top: -7px;
              left: 23px;
        }
        &.left-top::before{
              left: -7px;
        }
        &.top-left2::before{
            left: 118px;
            top: -7px;
        }
        &.top-right::before{
                right: 23px;
                top: -7px;
        }
    }

    .close-button{
            width: 19px;
    right: -7px;
    font-size: 10px;
    display: inline-block;
    text-align: center;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.66);
    border: solid 2px #001938;
    top: -7px;
    border-radius: 9px;
    /* -webkit-box-shadow: 0 2px 6px 0 rgba(36, 36, 36, 0.5); */
    box-shadow: 0 2px 5px -1 rgba(14, 12, 12, 0.95);
    background-color: #4760ee;
    position: absolute;
    cursor: pointer;
    transition: all .3s linear;
    }
    .close-button:hover{
    transform: scale(1.1); 
    
 }
   }

   .video-container{
       margin-top:15px;

       .video-description{
       font-size: 12px;
  font-weight: normal;
  font-style: italic;
   }
   }

   .tutorial-image{
      -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    display: flex;
    margin-right: 10px;
    justify-content: center;

    img{
            width: 45px;
            max-height: none !important;
            max-width: none !important;
    }
}
   

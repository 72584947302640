.animatedArrowContainer{
    width:100%;
    text-align:center;
        &.show{
      visibility: visible;
  opacity: 1;
  transition: height 1s , opacity 3s linear;
  height: 25px;
  cursor: pointer;
    }
  &.hide{
       visibility: hidden;
  opacity: 0;
  transition: height 1s  , visibility 0s 1s, opacity 0.5s linear;
  height:0px;
  }
}

.animated-arrow-lines {
  width: fit-content;
  display: inline-block;
  transition: transform .2s ease-in;

  .animatedArrowContainer:hover & {
    transform: scale(1.2);
  }

  .left {
    float: left;
    width: 32px;
    height: 4px;
    background: #6a7a8a;
    border-radius: 2px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);

    &.up {
      transform: rotate(-16deg);
    }

    &.down {
      transform: rotate(16deg);
    }
  }

  .right {
    &.up {
      transform: rotate(+16deg);
    }

    &.down {
      transform: rotate(-16deg);
    }

    margin-left: 28px;
    width: 32px;
    height: 4px;
    background: #6a7a8a;
    border-radius: 2px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  div {
    transition: 0.3s;
    border-radius: 1px;
  }

}
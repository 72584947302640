.mobile-insights-root {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
}

.mobile-insights-list-container {
    flex: 1;
    position: relative;
}

.mobile-insights-list {
    overflow-y: auto;
    position: absolute;
    width: 100%;
    height: 100%;
    padding-top: 1rem;
    background-color: #101f34;
}

.mobile-insights-list-item {
    padding-block: 8px;
    padding-inline: 16px;
    background: #003f5a;
    border-radius: 3px;
    margin-bottom: 8px;
    margin-inline: 8px;
    box-shadow: 2px 3px 4px #000000;
}

.mobile-insights-header-title {
    color: #fff;
    font-family: "Raleway", sans-serif;
    font-size: 1.1rem;
    margin-bottom: .5rem;
}

.mobile-insights-header-tags {
    font-style: italic;
    font-size: .75rem;
}

.mobile-insights-list-item-header {
    border-bottom: solid $border-width $main-border-color;
    padding-bottom: .5rem;
}
.mobile-insights-header-date,
.mobile-insights-header-risks {
    font-size: .75rem;
}

.mobile-insights-list-item-risk {
    margin-right: 8px;
    padding-right: 8px;
    font-size: 12px;
}
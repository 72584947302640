.gq-active-weight-details {
     font-size: 10px;
    color: rgba(255, 255, 255, 0.5);
    font-style: italic;
    min-width: 109px;
    max-width: 170px;
    margin-left: 10px;
  .gq-active-weight-details-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.gq-active-weight-temp{
      margin-left: -8px;
}

.gq-active-weight-details-progress {
  position: relative;
  height: 2px;
  width: 100%;
  background: rgba(#fff, .5);
  .gq-active-weight-details-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: rgba(#fff, .8);
    transition: width .5s ease-out;
  }
}

.gq-active-weight-name {

  font-size: 12px;
  color: rgba(#fff, .6);
  &.active {
    color: white;
  }
  .gq-active-weight-border-left {
       padding-right: 8px;
    margin-left: 4px;
    border-right: 1px solid rgba(255, 255, 255, 0.6);
    &.active {
      border-color: white;
    }
  }
}
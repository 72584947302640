.gq-template-component-notes {
  flex-shrink: 0;
}

.gq-temlpate-component-header {
  // min-height: 180px;
  padding: 8px;
  flex-shrink: 0;
  table {
    margin-bottom: 0;
  }
}

.gq-template-component {

  width: 800px;

  &.heatmap {
    width: 1050px;
  }
  &>div {
    width: 100%;
  }
}

.gq-temlpate-component-header-title {
  font-size: 15px;
}

.gq-temlpate-component-header-details {
  table {
    width: 100%;
  }
  tbody {
    background: none;
    border: none;
  }
  tr {
    background: none !important;
    &>td {
      .gq-chip {
        display: inherit;
      }
      .gq-temlpate-country-row {
        margin: 0 4px;
        .flag-icon {
          height: 16px;
          width: 16px;
          border-radius: 50%;
        }
        &:first-child {
          margin-left: 0;
        }
      }
      padding: 5px 8px;
      font-size: 13px;
      color: white;
      &>* {
        margin-right: 8px;
      }
    }
    &>td:first-child {
      color: rgba(#fff, .65);
    }
  }
}

.gq-temlpate-component-notes-row-index {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid white;
  color: white;
  font-size: 12px;
  margin: 0 4px;
}

.gq-temlpate-component-notes-row-text {
  font-size: 13px;
  color: rgba(#fff, .65);
}

.gq-template-component-container {
  position: relative;
}

.gq-template-country-row-name {
  vertical-align: middle;
}

.gq-template-component-watermark {
  pointer-events: none;
  height: 30px;
  img {
    width: auto;
    height: 50px;
    opacity: 0.4;
  }
}

.gq-template-component .heatmap-grid-container {
  overflow-y: visible;
}
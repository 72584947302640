.forgot-password-form {
    width: 400px;

    & > .auth-form-common__form {
        width: 260px;
        margin: 0 auto; 
    }
}

.mail-sent-container{
    text-align: center;
    width: 500px;
    div{
    margin-top: 10px;
    margin-bottom: 10px;
    }
    .check-your-email{
        font-size: 21px;
    }
    .follow-inst{
        font-size: 15px;
    }
    .back-to-login{
        margin-top: 50px;
        a{
            font-size: 15px;
             color: white;
        }
    }
}

    .back-to-login{
         font-size: 13px;
         a{
             color: white;
         }
    }
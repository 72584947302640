.new-country-picker-container {
    cursor: pointer;
    width: 286px;
    background-color: $general-sub-container-bg;
    font-size: 14px;
    position: relative;

    &:before {
        z-index: 999;
        transform: rotate(45deg);
        content: "";
        top: -7px;
        position: absolute;
        left: 20px;
        padding: 0;
        background-color: inherit;
        width: 20px;
        height: 20px;
    }

    &.align-right:before {
        right: 20px;
        left: auto;
    }

}

.new-country-picker-list-container {
    height: 386px;
    padding: 16px 0;
}

.new-country-picker-list {
    display: flex;
    flex-direction: column;
    max-height: 336px;
    overflow-y: overlay;
    list-style: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.new-country-picker-list-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    height: 30px;
}

.new-country-picker-list-item-text {
    color: $black;
    font-size: 13px;
    vertical-align: middle;
    transition: color .5s ease;

    .new-country-picker-list-item:hover & {
        color: $main-font-color;
    }
}

.new-country-picker-select-all-text {
    color: $main-font-color;
    font-size: 14px;
}

.new-country-picker-search-and-list-container {
    padding: 0 16px;
}

.new-country-picker-search-container {
    display: flex;
    padding-left: 10px;
    width: 100%;
    margin-top: 15px;
    border-radius: 3px;
    background: #001a39;
    align-items: center;

}

.new-country-picker-search-input {
    margin: 0;
    border: none;
    &:focus {
        border: none;
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        -webkit-appearance:none;
    }
}
.new-country-picker-search-icon {
    font-size: 17px;
    margin-left: -2px;
    margin-right: -2px;
    margin-top: 5px;
}

.new-country-picker-select-all-container {
    color: $main-font-color;
}

$flag-icon-margin: 0 8px;
.new-country-picker-list-item-flag-and-text-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & .flag-icon {
        margin: 0 8px;
    }
}

.new-country-picker-list-item-flag {
    border-radius: 50%;
    width: 16px;
    height: 16px;
}

.new-country-picker-select-all-icon{
    font-size: 18px;
    margin: 0 8px;
}
.new-country-picker-select-all-icon-and-text-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
@charset "UTF-8";
@import "lib/ff_settings";
@import "~foundation-sites/scss/foundation";
@import "~react-datepicker/dist/react-datepicker.css";
@include foundation-global-styles;
@include foundation-xy-grid-classes;
@include foundation-flex-classes;
@include foundation-forms;
@include foundation-switch;
@include foundation-label;
@include foundation-table;
@import "lib/vars";
@import "lib/mixins";
@import "lib/flagAddon/sass/flag-icon.scss";
@import "lib/components/all";
@import "lib/icons";
@import "lib/icons2";
@import "lib/topBlock/all";
@import "lib/leftBlock/all";
@import "lib/contentBlock/all";
@import "lib/rightBlock/sidebarRiskPicker";
@import "lib/nprogress";

.gq-container {
  border-radius: 3px;
  background-color: $general-container-bg;
}

.flag-icon {
  margin: 0 .25em 0 0;
  line-height: $flag-width / (4 / 3);
  width: $flag-width;
  min-width: $flag-width;
  box-sizing: content-box;
  border: none;
  pointer-events: all;
}

#root, .app {
  background-color: $app-main-color;
}

.app {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  color: $main-font-color;
  &.dragging {
    & * {
      cursor: move !important;
      cursor: -webkit-grabbing !important;
    }
  }
  &.blank {
    background-color: #fff;
    background-image: none;
    color: $app-main-color;
  }
}

.popup-caret {
  content: '';
  position: absolute;
  padding: 5px;
  transform: rotate(45deg);
  background-color: $general-popup-bg;
  border: 1px solid rgba(36, 36, 36, 0.5);
}

.gq-popup {
  border-radius: 3px;
  z-index: 1;
  position: fixed;
  background-color: $general-popup-bg;
  &.to-top {
    box-shadow: 0 -2px 6px 0 rgba(36, 36, 36, 0.5);
    &::after {
      @extend .popup-caret;
      top: -6px;
      border-bottom: 0;
      border-right: 0;
    }
  }
  &.to-bottom {
    box-shadow: 0 2px 6px 0 rgba(36, 36, 36, 0.5);
    &::after {
      @extend .popup-caret;
      bottom: -6px;
      border-top: 0;
      border-left: 0;
    }
  }
}

.gq-flag-popup {
  padding: 8px;
  color: rgba($main-font-color, .65);
  font-size: 12px;
}

.gq-flag {
  &.round {
    border-radius: 50%;
    height: 16px;
    @extend .flag-icon-squared;
  }
}

.filler {
  @extend .flex-child-grow;
}

html {
  box-sizing: border-box;
  height: 100%;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

body {
  height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga" on; // -webkit-text-stroke-width: 0.25px;
  overflow: hidden;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba($main-font-color, .3) !important;
    border-radius: 2px;
  }
  ::-webkit-scroll-track {
    background: transparent;
  }
}

.notification {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: -1px;
    right: 0;
    padding: 4px;
    background-color: #fd3d3c;
    border-radius: 50%;
  }
  &.n-s {
    &::after {
      padding: 3px;
    }
  }
}

span:focus {
  outline: none;
}

.gq-caret-container {
  display: inline-block;
  vertical-align: middle;
  line-height: 0.5;
  &.up {
    transform: rotate(180deg);
  }
}

.no-pointer-events {
  pointer-events: none;
}

.all-pointer-events {
  pointer-events: all;
}

.fluid {
  width: 100%;
  height: 100%;
}

.selectable-text,
.selectable-text *
 {
  -webkit-user-select: text;
  user-select: text;
}

body *:not(input):not(textarea) {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Open Sans', sans-serif;
}

// Note: we can no longer modify the original geoquant font file
// so we had to create a new file for newly created icons
@charset "UTF-8";

@font-face {
    font-family: "geoquant2";
    src: url("../../assets/fonts/geoquant2.eot");
    src: url("../../assets/fonts/geoquant2.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/geoquant2.woff") format("woff"),
        url("../../assets/fonts/geoquant2.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-weight: normal;
    font-style: normal;
}

[data-icon]:before {
    font-family: "geoquant2" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="gqi2-"]:before,
[class*="gqi2-"]:before {
    font-family: "geoquant2" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.gqi2-pulse:before {
    content: "\e405";
}
.gqi2-risk-and-pulse:before {
    content: "\e403";
}
.gqi2-risk:before {
    content: "\e404";
}
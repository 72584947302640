.unsub-container {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
}

.unsub {
    max-width: 1024px;
    color: #fff;
}

.unsub-error a {
    color: inherit;
}

.unsub-title {
    font-size: 18px;
    margin-block: 1rem;
    margin-inline: 1rem;
    text-align: center;
}
.welcome-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    flex-direction: column;

    &__init {
        font-size: 20px;
        font-family: 'Raleway', sans-serif;
    }

    &__logo {
        display: block;
        margin-top: 20px;
        & img {
            height: 60px;
        }
    }

    &__moto {
        font-size: 23px;
        line-height: 38px;
        text-align: center;
        margin-top: 44px;
        margin-bottom: 55px;
        
        &,
        & > b {
            font-family: 'Raleway', sans-serif;
        }
    }

    &__button {
        font-size: 19px;
        color: #f36f67;
        border-color: #f36f67;
        padding: 14px 40px;
        border-radius: 0;

        &:hover {
            color: #f36f67;
            border-color: #f36f67;
            background-color:  #f36f67 !important;
        }
    }

    &__prescription {
        font-size: 15px;
        line-height: 33px;
        color: #919ba9;
        margin-top: 66px;
        margin-bottom: 85px;

        a {
            color: #fff;
            text-decoration: none;
        }
    }

    &__social-links {
        a {
            text-decoration: none; 
            margin: 0 12px;

            img {
                width: 32px;
                height: 32px;
            }
        }
    }
}


.tutorial-container {
  z-index: 1337 !important;
  .gq-turorial-popup {
    z-index: 1337 !important;
  }
}

#gqTutPopup {
  transition: all .3 ease-in-out;
}

.gq-turorial-popup {
  border-radius: 3px;
  box-shadow: 0 0 9px 0 #07203a;
  position: fixed;
  max-width: 246px;
  max-height: 1000px;
  background: rgba($main-font-color, .2);
  color: rgba(255, 255, 255, .9);
  padding: 17px 16px 21px 16px;
}

.tutorial-title {
  font-size: 16px;
  font-weight: 600;
  flex-grow: 1;
}

.tutorial-content {
  font-size: 13px;
  line-height: 1.38;
  margin-top: 24px;
}

.tutorial-bottom-content {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.tutorial-top-content {
  @extend .row;
}

.tutorial-buttons {
  @extend .row;
}

.tutorial-steps {
  color: rgba($main-font-color, .4);
  font-size: 15px;
}

.tutorial-button {
  cursor: pointer;
  font-size: 15px;
  span {
    position: relative;
    transition: all .3s ease-in-out;
  }
  &:not(.disabled):hover {
    span {
      color: $main-font-color;
    }
    span:before {
      transform: scaleX(1);
    }
  }
  span:before {
    @include underline-transition(.2s, ease-in-out);
  }
  &.close {
    margin-left: 10px;
    color: rgba($main-font-color, .4);
    font-size: 13px;
  }
  &.prev {
    margin-right: 16px;
  }
  &.disabled {
    color: rgba($main-font-color, .4);
  }
}

.blurred {
  filter: blur(15px);
  * {
    filter: blur(15px);
  }
}

.no-blur {
  filter: none;
  z-index: 1336;
}

.clear-filter {
  @extend .no-blur;
  * {
    @extend .no-blur;
  }
}

.tutorial-focus {
  overflow: hidden;
  z-index: 1336;
  border-radius: 4px;
  @extend .clear-filter;
}

.blur-blanket {
  position: absolute;
  width: 100%;
  height: 100%;
  background: none;
  z-index: 1330;
}

.tutorial-marked {
  border: 1px solid $main-selection-color !important;
}

@import "../mixins";
@import "../vars";
$scrollbar-width: 10px;
.activity-history-container {
  max-width: 120rem;
  padding-left: 0;
  padding-right: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.activity-history-cell {
  overflow: hidden;

  &.activity-content {
    min-width: 680px;
    overflow: unset;
  }
}

.activity-cell-separator {
  width: 1px;
  background: rgba(#fff, .15);
  height: 100%;
}

.gq-activity-history-content {
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 24px;
  table {
    background: none;
    // margin-top: 16px;
    margin-bottom: 0;
    table-layout: fixed;
    width: 100%;
    max-width: 1000px;
    thead,
    tbody {
      background: none;
      border: none;
    }
    tbody {
      th,
      td {
        padding: 0;
      }
      tr {
        position: relative;
      }
      tr:nth-child(even) {
        background: none;
        border: none;
      }
      tr:hover {
        background-color: rgba(#fff, .1);
        color: #fff;
      }
    }
  }
  .gq-activity-history-table-container {
    padding: 24px 12px 24px 24px;
    overflow: hidden;
    height: 100%;
    min-width: 730px;
  }
  .activity-time-filter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    border-bottom: 1px solid rgba(#fff, .15);
    span:first-child {
      margin-right: 8px;
    }
    i {
      font-size: 16px;
      &::before {
        vertical-align: middle;
      }
    }
    .activity-time-filter-dropdown {
      cursor: pointer;
    }
  }
  .gq-activity-search-container {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0 8px;
    background: #1a304b;
    width: 335px;
    height: 32px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    input {
      margin: 0;
      width: 90%;
      background: none;
      border: none;
      color: rgba(#fff, 8);
    }
    // &:last-child {
    //   width: 10%;
    // }
  }
  .gq-activity-search-bar-container {
    position: relative;
    height: 32px;
    overflow: hidden;
  }
  .activity-history-container {
    display: flex;
    flex-direction: column;
    height: 100%;

  }
  .activity-history-virtualized {
      &::-webkit-scrollbar {
        width: $scrollbar-width;
      }
    }
    
  .activity-history-header {
    display: flex;
    flex: 0;
    margin-bottom: .5rem;
    width: calc(100% - #{$scrollbar-width});

    .activity-history-table-col {
      font-weight: 700;
    }
  }
  .activity-history-table-col {
    font-size: 13px;
    &:nth-child(1) {
      flex: 1.5;
    }
    &:nth-child(2) {
      flex: 6;
    }
    &:nth-child(3) {
      flex: 4;
    }
    &:nth-child(4) {
      flex: 8;
    }
    &:nth-child(5) {
      flex: 1;
    }
  }
  .activity-history-record {
    cursor: pointer;
    height: 28px;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: $black;
    background: none;
    border: none;
    transition: background .3s ease;
    &.active {
      background-color: rgba(#fff, .2);
    }
    &:hover {
      background-color: rgba(#fff, .1);
      color: #fff;
      .activity-record-row-menu-button-container {
          visibility: visible;
      }
    }
    .activity-history-record-type {
      i {
        color: $black;
        margin-left: 6px;
        vertical-align: middle;
        line-height: 1rem;
      }
      span {
        position: relative;
      }
    }
    .activity-history-record-type-notification {
      position: absolute;
      right: 0;
      top: 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #fd3d3c;
    }
    .activity-history-record-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .activity-history-record-created {
      &.unshared {
        opacity: 0.35;
        &::before {
          content: '(';
        }
        &::after {
          content: ')';
        }
      }
    }
    .activity-history-record-selection {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .flag-icon {
        width: auto;
        line-height: 9px;
      }
    }
  }
}

.gq-activity-history-content-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.activity-history-list {
  flex: 1;
}

.activity-history-view-wrapper {
  width: 50%;
}

.activity-view-container {
  max-width: 680px;
  margin: 24px 12px 24px 6px;
  padding: 16px 12px; // background-color: #21374f;
  overflow-y: auto;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 rgba(36, 36, 36, 0.5);
  align-items: center;
  .activity-view-image {
    margin: 16px auto;
    background-color: #001938;
    position: relative;
    min-width: 0;
    min-height: 415px;
    transition: all .5s ease;
  }
}

.activity-view-content {
  position: relative;
  width: 100%;
  margin-top: 12px;
  flex-shrink: 0;

  .activity-view-content-header {
    justify-content: space-between;
    .activity-view-title {
      font-size: 18px;
      color: #fff;
      white-space: inherit;
      overflow: hidden;
    }
    .activity-view-buttons {
      &>div {
        margin: 0 8px;
      }
      &>div:first-child {
        margin-left: 0;
      }
      &>div:last-child {
        margin-right: 0;
      }
    }
  }
  .activity-view-date {
    font-size: 16px;
    color: #ccc;
  }
  .activity-view-content-subheader {
    margin-top: 12px;
    &>div {
      display: inline-block;
      margin-bottom: 6px;
    }
    .gq-chip {
      background-color: #001a39;
    }
    &>div:first-child {
      margin-left: -2px;
    }
  }
  .activity-view-content-subheader-dates {
    margin-top: 24px;
    &>span {
      line-height: 20px;
    }
    i {
      font-size: 15px;
      margin-right: 8px;
      vertical-align: middle;
    }
    .activity-view-content-subheader-dates-span {
      font-size: 13px;
      color: #fff;
    }
  }
  .activity-view-content-subheader-tags {
    margin-top: 12px;
    &>span {
      line-height: 20px;
      i {
        font-size: 15px;
        margin-right: 8px;
        vertical-align: middle;
      }
      span {
        font-size: 13px;
        color: $black;
      }
    }
  }
}

.activity-time-filter-tooltip {
  padding: 10px 0;
  pointer-events: all;
  .activity-time-filter-tooltip-item {
    @extend .row;
    align-items: center;
    transition: background .5s ease, color .5s ease;
    cursor: pointer;
    height: 28px;
    font-size: 13px;
    color: $black;
    padding: 0 13px;
    min-width: 115px;
  }
}

.activity-view-tooltip {
  @extend .activity-time-filter-tooltip;
  .activity-view-tooltip-item {
    i {
      margin-right: 12px;
    }
    @extend .activity-time-filter-tooltip-item;
  }
}

.activity-view-no-data {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.activity-record-row-menu-button-container {
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  font-size: 16px;
  visibility: hidden;
}

.activity-view-content-text {
  margin-top: 40px;
  p {
    margin: 0;
  }
}

.predictions-table-header {
  display: flex;
  margin-right: 10px;
  margin-bottom: 10px;
  & div {
    color: #fff;
    font-weight: 700;
    font-size: 13px;
  }
}

.predictions-table-virtualized-container {
  width: 100%;
  height: 100%;
  max-width: 1000px;
}

.predictions-table-virtualized {
  font-size: 13px;
}

.prediction-item {
  display: flex;
  align-items: center;
  height: 100%;
  color: hsla(0,0%,100%,.65);
  &.active {
    background-color: hsla(0,0%,100%,.15);
  }
  &:hover {
    background-color: hsla(0,0%,100%,.1);
    color: #fff;
    cursor: pointer;
  }
}

.prediction-item-country {
  flex: .8;
  text-align: center;

  .prediction-item & {
    display: flex;
    justify-content: center;
  }
}
.prediction-item-description {
  flex: 2.5;
  max-width: 135px;
  text-align: left;
  .prediction-item & {
      max-width: 135px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
}

.prediction-item-call-date{
  flex: 1 0;
  white-space: nowrap;
}
.prediction-item-realization{
  flex: 1 0;
  white-space: nowrap;
}
.prediction-item-window{
  flex: .5;
  text-align: center;
}
.prediction-item-confidence{
  flex: 1;
  text-align: center;
}
.prediction-item-consensus{
  flex: 1;
  text-align: center;
}
.prediction-item-outcome{
  flex: 1;
  text-align: center;
}

.predictions-table {
  table-layout: auto !important;
  font-size: 13px;
  margin-top: 0;
  th:first-child {
    padding-left: 0;
    width: 53px;
  }
  th:nth-child(2) {
    text-align: left;
  }
  th {
    padding: 8px 4px;
    color: white;
    text-align: center;
  }
  tr {
    height: 28px;
    cursor: pointer;
    color: rgba(#fff, .65);
    text-align: center;
    transition: background .3s ease;
    td {
      padding: 4px !important;
    }
    &.active {
      td {
        background: rgba(#fff, .15);
      }
    }
  }
  .activity-history-record-description {
    text-align: left;
    white-space: nowrap;
    max-width: 135px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .activity-history-record-realization {
    white-space: nowrap;
  }
}

.prediction-view-container {
  width: 540px;
  padding: 8px;
  background: #001a39;
  box-shadow: 0 0 9px 5px rgba(0, 0, 0, 0.5);
  border-radius: 11px;
}

.prediction-view {
  &>div:not(.prediction-view-separator) {
    padding: 0 12px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  font-size: 13px;
  color: white;
}

.prediction-view-header {
  padding: 0 4px !important;
}

.prediction-view-separator {
  height: 1px;
  background: rgba(#fff, .15);
  width: 100%; // margin: 8px 0;
}

.prediction-flag {
  margin-right: 12px;
}

.prediction-view-description {
  p {
    white-space: pre-wrap;
  }
  p:first-child {
    font-size: 14px;
  }
}

.prediction-view-details {
  table {
    margin: 0;
    thead,
    tbody {
      background: none;
      border: none;
    }
    th {
      color: rgba(#fff, .4);
      padding: 0 8px;
      font-weight: normal;
    }
    td {
      font-size: 15px;
      color: rgba(#fff, .75);
      padding: 0 8px;
    }
  }
}

.prediction-view-insight-links {
  margin-top: 0px !important;
  color: rgba(#fff, .65);
  a {
    color: rgb(157, 171, 255);
    margin: 0 8px;
  }
}

.prediction-view-tags {
  color: rgba(#fff, .65);
  i {
    line-height: 1;
    margin-right: 8px;
  }
}

.activity-history-prediction-metrics {
  font-size: 24px;
  color: #fff;
  margin: 16px 0;
  & > div {
    display: inline-block;
  }
}

.activity-history-prediction-percent {
  font-weight: 800;
}

.activity-history-prediction-label {
  margin-left: 18px;
  font-weight: 300;
}

.insight-iframe-container {
  width: '100%';
  position: relative;

  min-height: 700px;
  @media (max-height: 720px) {
    min-height: 500px;
  }
}

.insight-iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

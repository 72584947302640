.gq-insight-note {
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  padding: 1.8rem 1rem 1rem 1rem;
  &:hover {
    background: rgba(#fff, .2);
  }
}

.gq-insight-note-edit-text {
  background-color: #1a304a;
  border-radius: 4px;
}

$insight-text-area-padding: 8px;
.gq-insight-note-edit {
  textarea {
    width: calc(100% - #{$insight-text-area-padding * 2});
    padding: $insight-text-area-padding;
    box-sizing: content-box;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba(#fff, .65);
    border: none;
    background: none;
    resize: none;
    min-height: 0px;
    overflow: hidden;
    margin: 0;
  }
}

.gq-insight-note-content {
  p {
    margin: 0;
    width: 100%;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba(#fff, .65);
    border: none;
    background: none;
    resize: none;
    min-height: 0px;
    white-space: pre-wrap;
    word-break: break-word;
  }
}

.gq-insight-note-index {
  flex-shrink: 0;
  margin: 2px 8px 2px 2px;
  font-size: 11px;
  color: white;
  border: 1px solid white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}

.gq-insight-note-date {
  font-size: 12px;
  color: rgba(#fff, .65);
  padding: 0;
  &.edit {
    padding: $insight-text-area-padding;
    color: white;
  }
}

.gq-insight-note-hover-buttons {
  position: absolute;
  padding-top: 8px;
  right: 0;
  top: 0;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  &.show {
    opacity: 1;
  }
}

.gq-insight-note-edit-buttons {
  font-size: 12px;
  margin-top: 16px;
  .gq-button-container:first-child {
    margin-right: 8px;
  }
}

.gq-insight-note-view {
  width: 100%;
}
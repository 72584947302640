$picker-bg: #21374f;
$picker-font-size: 15px;
$bar-margin-left: 8px;
.gq-cg-picker {
  position: absolute;
  top: 32px; // max-width: 1071px;
  width: 100%;
  padding: 0 32px;
  height: 33px;
  display: flex; // @extend .row;
  .gq-settings-input {
    flex-shrink: 0; // flex-shrink: 0;
  }
}

.gq-cg-group-name {
  @extend .row;
  align-items: center;
  border-radius: 3px;
  padding: 0 8px;
  background-color: $picker-bg;
  color: #fff;
  font-size: $picker-font-size;
  width: 200px;
  margin-right: 16px;
}

.gq-cg-picker-bar {
  width: 1px;
  @extend .row;
  flex-grow: 1;
  margin-right: 16px;
  border-radius: 3px;
  background-color: $picker-bg;
  padding: 0 16px 0 $bar-margin-left;
  .gq-cg-search {
    position: relative;
    @extend .row;
    justify-content: center;
    align-items: center;
    width: 220px;
    border-right: 1px solid #798696;
    &>i {
      color: rgba(#fff, .3);
      font-size: $picker-font-size;
      line-height: 10px;
    }
    &>input {
      display: flex;
      &::-webkit-input-placeholder {
        color: rgba(#fff, .3);
      } // &::placeholder {
      //     color: rgba(#fff, .3);
      // }
      outline: none;
      border: none;
      background: none;
      color: #fff;
      font-size: $picker-font-size;
      box-shadow: none;
      margin-bottom: 0;
    }
  }
}

.gq-cg-picker-country-list {
  flex-grow: 1;
  flex-shrink: 1;
  @extend .row;
  overflow: hidden;
}

.gq-cg-country-list-container {
  z-index: 2;
  position: absolute;
  top: 50px;
  left: -($bar-margin-left);
  width: calc(100% + #{$bar-margin-left});
  height: 185px;
  overflow: hidden;
  background-color: $picker-bg;
  padding: 16px;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 rgba(36, 36, 36, 0.5);
  ul {
    overflow-y: scroll;
    height: 100%;
    position: relative;
    padding: 0;
    margin: 0;
  }
  li {
    transition: background .5s ease;
    height: 30px;
    cursor: pointer;
    .flag-icon {
      width: 16px;
      height: 16px;
      margin: 0 8px;
      border-radius: 50%;
    }
    span {
      color: $black;
      font-size: 13px;
      vertical-align: middle;
      transition: color .5s ease;
    }
    .spinner {
      display: inline;
      &>div {
        width: 5px;
        height: 5px;
      }
    }
    &.focused {
      background-color: rgba(#fff, 0.2);
      span {
        color: #fff;
      }
    }
    &:hover {
      span {
        color: #fff;
      }
    }
  }
}

.gq-cg-selected-list {
  height: 100%;
  max-width: 400px;
  position: relative;
  overflow: hidden;
  .gq-cg-selected-item-wrapper {
    position: absolute;
    perspective: 800px;
    will-change: transform;
  }
  .gq-cg-selected-item {
    position: relative;
    margin-right: 4px;
    font-size: 14px;
    transform-style: preserve-3d;
    @extend .row;
    align-items: center;
    &:first-child {
      margin-left: 8px;
    }
    .flag-icon {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      backface-visibility: hidden;
      margin: 0;
    }
    .gq-cg-selected-item-hover {
      font-size: 12px;
      text-align: center;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #21374f;
      color: white;
      backface-visibility: hidden;
      border: 1px solid white;
      border-radius: 50%;
      transform: rotateY(180deg);
    }
    span {
      backface-visibility: hidden;
      will-change: transform;
    }
  }
}

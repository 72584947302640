@import "../mixins";
@import "../vars";
@keyframes bg-click {
  from {
    background-color: $timeline-button-bg
  }
  to {
    background-color: rgba(255, 255, 255, .9)
  }
}

.rounded-flag {
  width: 16px;
  height: 16px;
  margin: 0;
  border: 1px solid rgba(48, 71, 97, 0.81);
  background-clip: content-box;
  border-radius: 50%;
}

.hidden-svg {
  pointer-events: none;
  fill-opacity: 0;
  stroke-opacity: 0;
}

.chart-container {
  position: relative;
  width: 100%;
  flex: 0 0 220px;
  transition: background-color, 200ms, ease;

  .chart-row {
    @extend .row;
  }
  .chart-col {
    @extend .col;
    position: relative;
    &:first-child {
      flex-grow: 1;
    }
    &:last-child {
      flex-shrink: 0;
      width: 15px;
    }
  }
  &.expanded {
    height: 100%;
    flex: 1 1 100%;
    width: 100%;
    &.opaque {
      background-color: rgba($timeline-chart-bg, 1);
    }
    background-color: $app-main-color;
  }
  &:not(.expanded)::before {
    content: 'Preview';
    position: absolute;
    top: 16px;
    width: 54.6px;
    left: 0;
    height: 16px;
    background-color: rgba(#fff, .15);
    cursor: default;
    font-size: 11px;
    color: rgba($main-font-color, .6);
    text-align: center;
  }
  .graph-line-flag {
    border-radius: 50%;
    background-clip: content-box;
    width: 1em;
    height: 1em;
    border: 1px solid rgba(48, 71, 97, 0.81);
  }
  .flag-container {
    cursor: default;
  }
}

.virtual-today-bar {
  width: 1px;
  fill: white;
}

.actual-today-bar {
  width: 1px;
  fill: #fd3d3c;
}

.chart-actual-today-text {
  font-size: 10px;
  fill: white;
  transform: rotate(-90deg);
}

.virtual-today-pin-outer-bar {
  fill: white;
}

.virtual-today-pin-inner-bar {
  fill: #40536a;
}

.chart-preview-tab {
  cursor: default;
  position: absolute;
  top: -10px;
  left: 28px;
  font-size: 0.75em;
  color: rgba($main-font-color, .65);
  border-top: $timeline-chart-preview-tab-border solid 1px;
  border-left: $timeline-chart-preview-tab-border solid 1px;
  border-right: $timeline-chart-preview-tab-border solid 1px;
  padding: 0 5px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.chart-virtual-today {
  cursor: col-resize;
  .virtual-today-dragArea {
    fill: rgba(0, 0, 0, 0);
  }
}

.virtual-today-text-container {
  opacity: .7;
}
.virtual-today-text {
  fill: white;
  font-size: 12px;
  font-family: "Opens-Sans Light", sans-serif;
}

.gq-active-weight-button{
  width: 33%;
}

.delete-selected-popup-data{
    width: 18px;
    font-size: 12px;
    text-align: center;
    margin-top: 5px;
    right: 0px;
    border-radius: 12px;
    color: #a6afb9;
    height: 18px;
    background-color: #21374f;
    cursor: pointer;
    i{
    margin-top: 2px;
    height: 12px;
    display: inline-block;
    }
    &:hover{
      background-color: white;
      color: #21374f;
    }
}

.compare-button-section {
  width: 33%;
  display: flex;
  justify-content: flex-end;
  margin-right: 12px;
}

#compareButton{
    // margin-right: 42px;
    width: 80px;
    height: 22px;
    font-size: 12px;
    /* margin: 0px 9px; */
    color: #a6afb9;
    border: 0.5px solid #55585a;
    border-radius: 11.5px;
    padding: 1px;
    display: -webkit-box;
    margin-top: 2px;
    right: 0px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    span{
      margin-left: 7px;
    }
    &:hover{
      background-color: white;
      color: #001938;
      border: 0.5px solid white;
    }
    i{
              margin: 0px;
    font-size: 28px;
    margin-top: -9px;
    margin-left: -3px;
    /* height: 18px; */
    padding: 0px;
    }
}

.timeline-bottom-bar {
  position: absolute;
  bottom: 0;
  left: 28px;
  width: calc(100% - 43px);
}

#buttonsContainer {
  width: 100%;
  padding-top: 4px;
  @extend .row;
  justify-content: space-between;
  position: relative;
  &.expanded-chart {
    margin-right: 30px;
    margin-left: 27px;
  }
  #gqCountryList {
    @extend .row;
    align-items: center;
    overflow-y: hidden;
    overflow-x: auto;
    flex-shrink: 1;
    &::-webkit-scrollbar {
      background-color: rgba($main-font-color, .1);
      height: 4px;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $dimension-item-active-bg-color;
    }
  }
  // .flag-container {
  //   cursor: pointer;
  //   * {
  //     pointer-events: none;
  //   }
  // }
  .selected-compare-item{
    font-size: 13px;
    right: 0px;
    margin-right: 5px;
    height: 20px;
    margin-top: 3px;
  }
}

.timeline-button {
  margin: 0 2.5px;
  &.active>i {
    color: $main-selection-color !important;
  }
}

.timeline-button.ui.icon.button {
  background-color: $timeline-button-bg !important;
  border-radius: 2px;
  object-fit: contain;
  padding: 0;
  width: 23px;
  height: 23px;
  cursor: pointer;
}

.timeline-button>i {
  color: rgba($main-font-color, .65);
  width: 23px;
  height: 23px !important;
}

#deselectButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  position: relative;
  &::after {
    content: 'x';
    position: absolute;
    right: 20%;
    top: 0;
    font-size: 10px;
  }
  .timeline-button {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    &>i {
      padding: 3px 5.5px;
    }
    &.animate {
      animation: bg-click 1s;
    }
  }
}

.range-handle-tooltip-content {
  padding: 8px;
}

// #timelineButtons {
//   align-items: center;
//   * {
//     box-sizing: content-box;
//   }
//   @extend .row;
//   &>div {
//     margin-right: 4px;
//   }
//   &>div:last-child {
//     margin-right: 0;
//   }
//   #expandButton {
//     background-color: $timeline-button-bg;
//     border-radius: 2px;
//     object-fit: contain;
//     width: 23px;
//     height: 23px;
//     cursor: pointer;
//     &.disabled {
//       opacity: 0.5;
//       cursor: not-allowed;
//     }
//     &.active {
//       .timeline-button-svg {
//         stroke: $main-selection-color;
//       }
//     }
//     .timeline-button-svg {
//       fill: $timeline-button-bg;
//       stroke: $timeline-button-color;
//     }
//   }
//   #toggleOpacityButton>i {
//     padding-top: 3px;
//   }
//   #toggleEventsButton>i {
//     padding-top: 4px;
//   }
//   #toggleGraphLinesButton>i {
//     padding-top: 4px;
//   }
// }

#timelineButtons{
  display: flex;
    /* flex-direction: column; */
  margin: 0px 43px 0px 18px;
  justify-content: center;
  width: 33%;

  #centerButtons{
      display: flex;
    /* justify-self: center; */
    margin: auto;
    position: relative;
    }

}

.xAxis {
  fill: none;
  stroke: none;
  path {
    stroke: none;
  }
  .tick {
    line {
      stroke: rgba($main-font-color, 0.65);
    }
  }
  line {
    stroke: none;
  }
  text {
    stroke: none;
    fill: white;
    font-size: 10px;
  }
}

.yAxis {
  path {
    stroke: none;
  }
  line {
    stroke: rgba(255, 255, 255, .15);
  }
  text {
    fill: none;
  }
  &.expanded {
    text {
      fill: white;
    }
  }
}

.externalDatayAxis {
  path {
    stroke: none;
  }
  line {
    stroke: #d8d8d861;
  }
  text {
    fill: none;
  }
  &.expanded {
    text {
      fill: white;
    }
  }
  & .hidden {
    display: none;
  }
}

.hover-dot {
  stroke: none;
  fill: rgba(0, 0, 0, 0);
  &.hide {
    @extend .hidden-svg;
  }
}

.chart-circle {
  stroke: $timeline-event-dot-stroke;
  transition: fill-opacity, 200ms, ease;
  &.hide {
    @extend .hidden-svg;
  }
  &.active {
    fill: $main-selection-color;
    r: 8;
  }
  &:hover {
    r: 8;
  }
}

.range-horizontal-slider {
  height: 19px;
  margin: 4px 17px 2px 0;
  border: 1px solid #26374b;
   border-radius: 7.5px;
  background-color: #1a2b3e;
}

.expanded-slider{
  margin: 4px 42px 2px 26px;
}

.range-vertical-slider {
  width: 15px;
  height: calc(100% - 10px);
  border: 1px solid #26374b;
  max-height: 172px;
  bottom: 0;
  position: absolute;
  .range-slider-draggable {
    height: 18px;
  }
}

.range-slider-draggable {
  background-color: rgba($main-font-color, .12);
  height: 100%;
  position: relative;
  &.dragged {
    background-color: $main-selection-color;
  }
}

.chart-line {
  stroke-width: $timeline-graph-line-width;
  fill: none;
  transition: stroke-opacity, 200ms, ease;
  &:not(.currency-line):not(.currency-dashed).hide {
    @extend .hidden-svg;
  }
  &.pulse {
    stroke-width: $timeline-graph-line-width / 2;
    stroke-opacity: .7;
    stroke-dasharray: 1;
    &.single-line {
      stroke-width: $timeline-graph-line-width;
      stroke-opacity: 1;
    }
  }
}

.graph-today-value {
  fill: white;
  font-size: 12px;
  font-family: "Opens-Sans Light", sans-serif;
}

.uscore-tooltip-data {
  @extend .col;
  justify-content: center;
  font-size: 13px;
  padding: 8px;
  &>div {
    line-height: 1.3;
    @extend .row;
    justify-content: center;
  }
  .uscore-date {
    color: rgba($main-font-color, .65);
  }
  .uscore-impact {
    color: $main-font-color;
  }
}

.date-bar {
  fill: $main-font-color;
}

.date-text {
  fill: $main-font-color;
  font-size: 10px;
}

.chart-indicator {
  position: absolute;
  .flag-icon {
    @extend .rounded-flag;
  }
  &.name-indicator {
    width: 32px;
    height: 32px;
    border-width: 2px;
    border-style: solid;
    color: #fff;
    font-size: 12px;
    background-color: #21374f;
    @extend .row;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    &.square {
      border-radius: 0;
    }
  }
}

.range-slider-draggable {
  @extend .row;
  justify-content: space-between;
  .range-handle {
        width: 18px;
    height: 17px;
    padding: 1px 2px;
    background-color: #fff;
    border-radius: 9px;
    justify-content: space-between;
    &:hover{
    cursor: ew-resize;
    }
    @extend .row;
    justify-content: space-between;
    .range-handle-inner-bar {
    height: 100%;
    margin: 0px 1px;
    width: 2px;
    background-color: #40536A;
    }
  }
    #leftHandle{
      margin-left: -6px;
    }
    #rightHandle{
      margin-right: -6px;
    }
}

.range-slider-draggable {
  cursor: col-resize;
  cursor: -webkit-grab;
  &.dragged {
    cursor: -webkit-grabbing;
  }
}

.gq-graph-toolbar-separator {
  width: 1px;
  height: 100%;
  background-color: rgba(#fff, .6);
}

.gq-chart-insight-indicator {
  position: absolute;
  background: white;
  &.gq-inight-hover-dot {
    border-radius: 50%;
  }
}

@media screen and (max-width: 1280px) {
  #gqCountryList {
    padding-bottom: 2px;
  }
  .timeline-bottom-bar {
    padding-bottom: 6px;
  }
  #buttonsContainer {
    .flag-container {
      border-width: 3px;
    }
    .flag-icon {
      min-width: 12px;
      width: 12px;
      height: 12px;
    }
  }
}

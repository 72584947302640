
@import "../vars";

.gq-top-indicators-container {
    flex-shrink: 0;
    width: 285px;
    overflow: hidden;
     border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding-bottom: 10px;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 16px 16px 0;
    .collapse-button {
        transition: transform .5s ease;
        &.collapsed {
            transform: rotate(180deg);
            position: relative;
        }
    }
}

.gq-top-indicators-title {
    color: white;
    font-size: 13px;
        margin-bottom: 8px;
    @extend .row;
    align-items: center;
}

.gq-top-indicators-content {
    max-height: 900px;
    overflow: hidden;
    transition: all .5s ease;
    &.collapsed {
        min-height: 0;
        height: 0;
    }
}

.gq-top-indicators-item {
    align-items: center;
    border-radius: 3px;
    transition: background .3s ease-in;
    padding: 4px 8px;
    height: 24px;
    margin: 2px 0;
    font-size: 12px;
    background-color: $general-sub-container-bg;
    cursor: pointer;
    &:hover {
        background-color: rgba(#fff, .2);
    }
}

.gq-top-indicators-dimension {
    flex-grow: 1;
    margin: 0 8px;
    color: rgba($main-font-color, .65);
}

.gq-top-indicators-score {
    &.positive {
        color: $positive-score;
    }
    &.negative {
        color: $negative-score;
    }
}

.gq-text-editor-link-popup {
  font-size: 15px;
  padding: 4px 8px;
  max-width: 292px;
  pointer-events: all;
}

.gq-text-editor-link-popup-href {
  border: none;
  padding: 0;
  margin: 0;
  transition: border .5s ease;
  color: white;
  height: auto;
  ::-webkit-input-placeholder {
    color: rgba(#fff, .4);
  }
  &:focus {
    border: none;
  }
}
.gq-tabs {
  width: auto;
  padding: 13px 0;
  border-bottom: 1px solid rgba(#fff, .15);
  position: relative;
  display: flex;
  justify-content: center;

  .mobile-content & {
    padding: 20px 0;
  }
}

.gq-tab {
  padding: 0px 20px;
  flex: 1;
  
  &:last-child {
    margin-right: 0;
  }
}

.gq-tab-button {
  font-size: 13px;

}

.tab-border {
  height: 1px;
  border: 0.5px solid #fff;
  transition: transform .5s ease;

}
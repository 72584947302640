
@import "../vars";

.gq-settings-container {
    height: 100%;
    @extend .col;
    & > div {
        width: 100%;
        height: 100%;
    }
}

.gq-settings-header {
    height: 52px;
    font-size: 17px;
    color: rgba($main-font-color, .4);
    border-bottom: 1px solid rgba(#fff, .15);
    @extend .row;
    flex-shrink: 0;
    justify-content: center;
}

.gq-settings-header-menu {
    @extend .row;
}

.menu-item-label-border {
    position: absolute;
    width: 130px;
    bottom: 0;
    transition: transform .5s ease, opacity .5s ease;
    will-change: transform, opacity;
    border-bottom: 2px solid $main-font-color;
    opacity: 0;
    transform: scaleX(0);
}

.gq-settings-menu-item {
    position: relative;
    cursor: pointer;
    transition: color .5s ease;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: -5px;
    &.active {
        color: $main-font-color;
        & > .menu-item-label-border {
            opacity: 1;
            transform: scaleX(1);
        }
    }
    &:not(.active):hover {
        color: rgba($main-font-color, .8);
    }
}

.gq-settings-content {
    @extend .row;
    flex-grow: 1;
    overflow: hidden;
}

.gq-country-mode-select {
    height: 100%;
    @extend .row;
    justify-content: center;
    .gq-country-mode-picker {
        background: #21374f;
        position: absolute;
        top: 25px;
        width: 279px;
        color: rgba(255, 255, 255, 0.4);
        z-index: 1;
        border-radius: 3px;
        @extend .row;
        align-items: center;
        color: rgba(#fff, .4);
        input {
            flex-grow: 1;
            background: none;
            outline: none;
            border: none;
            color: rgba(#fff, 65);
            &::placeholder {
                color: rgba(#fff, .4);
                background: none;
            }
        }
        .gq-cg-country-list-container {
            width: 279px;
            left: 0;
        }
        .gq-country-mode-picker-wrap {
            height: 33px;
            display: flex;
            align-items: center;
            width: 100%;
            padding: 8px;
            input {
                margin: 0;
                height: auto;
                padding: 0 8px;
            }
        }
    }
}
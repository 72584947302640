$oval-button-bg: #4760ee;
.gq-oval-button {
  background-color: $oval-button-bg;
  color: white;
  border-radius: 16px;
  overflow: hidden;
  font-size: 13px;
  font-weight: bold;
  padding: 4px 16px;
  margin: 0 24px;
  white-space: nowrap;
  transition: color .3s ease, background .3s ease;
  cursor: pointer;
  &.disabled {
    background-color: #ccc;
    color: #333;
    cursor: not-allowed;
  }
}
.prompt-input {
    background-color: inherit;
    color: inherit;
    border: 1px solid white;
    padding: 4px;
    outline: none;
    border-radius: 8px;
}

.prompt-headline-delete {
    font-size: 24px;
    display: flex;
    cursor: pointer;
    padding-inline: 8px;
}

.reached-limit {
    animation: blinker 1s linear infinite;
    color: red;
    margin-inline-start: 1rem;
    font-size: 2rem;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}
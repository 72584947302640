.centered-flex-col-200 {
  @extend .col;
  justify-content: center;
  width: 200px;
}

.slider-bar {
  height: 3px;
  margin: 15px 0;
  width: 100%;
}

.article-container {
  position: relative;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 16px;
  .divider {
    margin-bottom: 16px;
    padding-top: 8px;
    width: 95%;
    border-bottom: solid 1px $divider-border-color;
  }
}

.score-drag-handle {
  cursor: pointer;
  cursor: -webkit-grab;
  &.dragging {
    cursor: -webkit-grabbing;
  }
}

.article-content {
  &.loading {
    filter: blur(5px);
  }
}

.button-container {
  @extend .row;
  justify-content: flex-end;
}

.article-left {
  margin: 0 auto;
  max-width: 600px;
  @extend .col;
  .article-button-container {
    @extend .row;
    justify-content: flex-start;
    flex-shrink: 0;
    .close-button {
      button {
        font-size: 12px;
      }
    }
  }
}

.form-wrapper {
  position: relative;
  height: 296px;
}

.article-right {
  @extend .col;
  max-width: 260px;
  width: 260px;
  margin: 0 auto;
  .score-form {
    width: 100%;
    .article-header {
      font-size: 13px;
      color: rgba($main-font-color, .65);
      margin-bottom: 8px;
    }
    .article-form-content {
      padding: 16px;
      .form-row {
        @extend .row;
        justify-content: space-between;
        line-height: 18px;
        margin-bottom: 8px;
        font-size: 13px;
        &>div {
          @extend .row;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      .form-item-header {
        color: rgba($main-font-color, .65);
        #countryHeader {
          @extend .row;
        }
      }
      .form-item-value {
        @extend .row;
        #dimensionValue {
          font-weight: bold;
          color: $main-font-color;
        }
      }
      #scoreSubmitButton {
        justify-content: center;
        .button-submit {
          font-size: 13px;
        }
      }
    }
  }
  .past-scores-header {
    @extend .row;
    justify-content: space-between;
    font-size: 13px;
    color: rgba($main-font-color, .65);
    margin-bottom: 4px;
  }
  #expandScores {
    cursor: pointer;
    span {
      position: relative;
      &:hover {
        &::before {
          transform: scaleX(1);
        }
      }
      &::before {
        @include underline-transition(.2s, linear);
      }
    }
  }
  .sliderDragger {
    cursor: pointer;
  }
  .score-item-menu-icon {
    @extend .row;
    align-items: center;
    position: absolute;
    right: -16px;
  }
  .score-item-menu {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    @extend .row;
    justify-content: center;
    align-items: center;
  }
  .menu-buttons-container {
    width: 100%;
    height: 100%;
    position: absolute;
    @extend .row;
    justify-content: flex-end;
    align-items: center;
  }
  .gq-dialog-message {
    @extend .row;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    width: 100%;
    height: 100%;
    font-size: 12px;
    .gq-dialog-left {
      flex-grow: 1;
    }
    .gq-dialog-right {
      @extend .row;
    }
  }
  .score-item {
    @extend .row;
    align-items: center;
    flex-shrink: 0;
    border-radius: 3px;
    margin-bottom: 2px;
    position: relative;
    padding: 0 4px;
    background-color: $general-sub-container-bg;
    height: 24px;
    &:last-child {
      margin-bottom: 0;
    }
    .bg-cover {
      border-radius: 3px;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transition: background .3s ease;
      background: none;
      &.active {
        background: rgba($general-sub-container-bg, .9);
      }
    }
    .score-item-col {
      @extend .row;
      justify-content: flex-start;
      font-size: 12px;
      color: rgba($main-font-color, .65);
      white-space: nowrap;
      padding: 0 1px;
      &.col-2 {
        justify-content: flex-end;
        flex-grow: 1;
        width: 20%;
      }
      &.block-ellipsis {
        display: block;
        text-overflow: ellipsis;
        width: 25%;
        margin-right: 15px;
      }
      &.impact-col {
        flex-grow: 2;
      }
    }
  }
}

.article-right-section {
  margin-bottom: 24px;
}

#countryPicker {
  align-items: center;
  >i {
    color: white;
    line-height: 10px;
  }
  cursor: pointer;
  max-width: 100%;
}

.article-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  color: white;
  z-index: 1100;
}

.article-header-container {
  @extend .col;
  flex-shrink: 0;
  .article-header {
    font-size: $article-header-font-regular;
    line-height: 1.21;
    font-weight: bold;
    text-align: left;
    color: $article-text-font-color;
  }
  .article-subheader {
    @extend .row;
    align-items: center;
    margin-top: 8px;
    .article-details {
      color: $article-date-font-color;
      font-size: $article-date-font-size;
      .article-source::after {
        content: '·';
        padding: 0 5px;
      }
      .article-link {
        font-size: $article-link-font-size;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .article-flags {
      padding-left: .5em;
    }
  }
}

.article-text {
  font-size: $article-text-font-regular;
  line-height: 1.5;
  text-align: left;
  color: $article-text-font-color;
  flex: 1;
  overflow-y: auto;
  &>p {
    padding-right: 16px;
  }
  a {
    color: white;
    &:visited {
      color: white;
    }
  }
}

.article-score-form-container {
  padding: 1em;
  @extend .col;
  width: 100%;
  border-radius: 3px;
  background-color: $article-score-form-bg;
  margin-bottom: 1em;
  .score-form-header {
    @extend .row;
    justify-content: center;
    font-size: $article-score-form-header-font-size;
    color: $article-score-form-header-color;
    padding-bottom: 1em;
  }
  .buttons-container {
    padding-top: 1em;
    @extend .row;
    justify-content: center;
  }
}

#countryList {
  padding-left: 0.5em;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.custom-dropdown {
  * {
    cursor: pointer;
  }
  height: auto;
  max-height: 300px;
  overflow-y: auto;
  padding: 5px 0;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.15) 0 2px 18x 0;
  background-color: $custom-drop-down-bg;
  z-index: 2;
  color: $custom-drop-down-color;
  .dropdown-item {
    @extend .row;
    padding: 4px 8px;
    width: auto;
    transition: background .3s ease;
    &:hover {
      background-color: rgba(#fff, .2);
    }
    .dropdown-flag {
      pointer-events: none;
      margin-right: 4px
    }
  }
  .dropdown-checkbox {
    @extend .row;
    align-items: center;
    margin-right: 8px;
    width: 10px;
  }
  .dropdown-label {
    @extend .row;
    align-items: center;
    font-size: 10px;
  }
  .dropdown-checkbox,
  .dropdown-label {
    pointer-events: none;
  }
}

.impact-duration-container {
  width: 100%;
  @extend .row;
  font-size: $article-score-form-header-font-size;
  color: $article-score-form-header-color;
  justify-content: space-evenly;
  div {
    @extend .row;
    justify-content: center;
  }
  .slider-label {
    color: rgba($main-font-color, .65);
    text-transform: uppercase;
    font-size: $article-slider-font-size;
  }
  .impact {
    @extend .centered-flex-col-200;
    .impact-slider-bar {
      @extend .slider-bar;
      background-image: linear-gradient(to left, rgb(255, 56, 56), rgb(33, 210, 133));
    }
  }
  .duration {
    @extend .centered-flex-col-200;
    .duration-slider-bar {
      @extend .slider-bar;
      background-image: linear-gradient(to left, rgb(255, 255, 255), rgba(255, 255, 255, 0.2));
    }
  }
}

.impact-slider-bar {
  @extend .slider-bar;
  background-image: linear-gradient(to left, rgb(255, 56, 56), rgb(33, 210, 133));
}

.duration-slider-bar {
  @extend .slider-bar;
  background-image: linear-gradient(to left, rgb(255, 255, 255), rgba(255, 255, 255, 0.2));
}

.toast-container {
  position: fixed;
  width: 100%;
  left: 0;
  top: 1rem;
  z-index: 1;
  @extend .row;
  justify-content: center;
  pointer-events: none;
  .toast {
    font-size: 1rem;
    min-width: 200px;
    width: auto;
    padding: 0 10px;
    height: 30px;
    border-radius: 3px;
    color: $toast-color;
    @extend .row;
    align-items: center;
    justify-content: center;
    transition-property: opacity;
    transition-duration: 500;
    transition-timing-function: ease;
    &.error {
      background-color: $toast-error-color;
    }
    &.success {
      background-color: $toast-success-color;
    }
  }
}

.right-wrapper {
  flex-grow: 1;
  flex-shrink: 0;
}

.left-wrapper {
  flex-grow: 2;
  flex-shrink: 1;
  @extend .row;
  margin-right: 16px;
}

.dropdown-checkbox-input {
  margin: 0;
  height: .55em;
}

$active-border: 1px solid $black;
.gq-settings-input {
  width: auto;
  border-bottom: 1px solid transparent;
  height: 33px;
  background: #21374f;
  padding: 0 8px;
  @extend .row;
  justify-content: space-between;
  align-items: center;
  transition: border-color .5s ease-out;
  border-radius: 3px;
  &>input {
    &::selection {
      color: #fff;
      background: rgb(204, 226, 255);
    }
    &::placeholder {
      color: #fff;
    }
    width: 100%;
    border: none;
    background: none;
    color: #fff;
    font-size: 15px;
    outline: none;
  }
  &>i {
    transition: opacity .5s ease-out;
    opacity: 0;
  }
  &.active {
    border-bottom: $active-border;
    &>input {
      color: $black;
      &::placeholder {
        color: $black;
      }
    }
    &>i {
      opacity: 1;
    }
  }
  &.no-edit {
    pointer-events: none;
  }
}
.external-data-popup-content {
width: 250px;
height: auto;
    .external-data-top-content{
        display: flex;
        flex-direction: column;
        padding: 15px;
        padding-bottom: 0px;

    }
    .external-data-item{
        display: flex;
        font-size: 12px;
        margin-bottom: 20px;

        .checkbox-container {
              display: block;
              position: relative;
              padding-left: 26px;
             cursor: pointer;
              margin-top: 3px;
              /* font-size: 22px; */
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
                 user-select: none;
        }
    }
    .drop-down-list{
            height: 73px;
    overflow-y: scroll;
    /* height: 100%; */
    position: relative;
    padding: 0px;
    margin: 5px 15px;
    background-color: #001a39;
    border-radius: 5px;

    }
     .drop-down-item{
padding: 5px;

    &:hover{
          background-color:  #21374f;
      }
      &.selected{
           background-color: #21374f;
      }
     }

    .drop-down-selected-item {
            margin: 10px;
    background-color: #001a39;
    border-radius: 3px;
    padding: 5px;
    height: 30px;
    display: flex;

    .selected-item-name{
        flex-grow: 1;
    }
    .arrowOpen{
            transform: rotate(180deg);
    }
    }
    .drop-down-container{
    }

    .drop-down-list-element{

    position: absolute;
    height: 100px;
    top: -5px;
    overflow: auto;
    margin: 10px;
    border-radius: 3px;
    padding: 5px;
    width: 230px;
    background-color: #001834;
    }
}

.select-from-list{
 font-style: italic;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #667588;
  
  &.custom-dropdown-search {
      border: none;
      height: unset;
      padding: unset;
      font-size: unset;
      &::placeholder {
          color: #667588;
      }
  }
}
@charset "UTF-8";
@import "../vars";

#topEventsWrapper{
      position: fixed !important;
    width: 317px;
    background-color: #001736;
    z-index: 10000;
}

.events {
  @include fixed-flex(327px);
  background-color: $secondary-bottom-gradient;
  @extend .col;
  overflow: hidden;
  position: relative;
  transition: all .5s ease;
  &.minimized {
    @include fixed-flex(15px);
  }
  }
    .eventsHeaderTitle{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
        align-items: flex-end;
    padding-bottom: 10px;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    margin: 12px 22px 0px 16px;
    &#alertFeed{
      margin: 0px;
      padding: 0px;
    }
      &.noFlag{
      align-items: end;
          margin-top: 7px;
    }
    .flag-icon{
          border-radius: 50%;
    width: 18px;
    width: 16px;
    height: 16px;
    background-size: cover;
    background-position-x: center;
    border: 1px solid rgba(48, 71, 97, 0.81);
    -webkit-background-clip: content-box;
    background-clip: content-box;
    /* border-radius: 50%; */
    /* height: 14px; */
    }
    .hidden{
      display:none;
    }
    /* padding-bottom: 0px; */
    .eventsTitle {
      padding-top: 2px;
      font-size: 13px;
      font-weight: bold;
      transition: opacity 2s;
      &.hide {
        opacity: .2;
      }
    }
    .countryNumbers{
      font-size:13px;

    }
    i{
    padding: 3px 5px 2px 2px;
    /* font-size: 14px; */
    font-size: 17px;

    color: #6a7a8a;
    }
    }
  .eventsHeader{
    border-bottom: 1px solid;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    margin: 12px 22px 0px 16px;
    &.no-border{
      border: 0px;
    }

  }
  .collapse-button {
        transition: transform .5s ease;
            padding-left: 0px;
        &.collapsed {
            transform: rotate(180deg);
            position: relative;
            padding-right: 0px;
            padding-left: 8px;
        }
    }

.topEventsContainer {
max-height: 900px;
    overflow: hidden;
    transition: max-height 1s ease;
    //transition: all .5s ease;
    &.collapsed {
       max-height: 0px
    }
}

#eventsContainer {
  @extend .col;
  width: 312px;
   height: 100%;
  position: relative;
  overflow: hidden;
  &.minimized {
    width: 0;
  }
}

#eventsWrapper {
  padding-right: 15px;
  width: 320px;
  height: 100%;
}

#tempEvents {
  max-height: 100%;
  overflow: auto;
}

#eventsMenu {
  display: flex;
  flex-direction: column;
      margin-bottom: 15px;
}

.gq-events-feed-menu-buttons {
  @extend .row;
  justify-content: space-between;
  flex-shrink: 0;
  width: 85%;
  margin: 0 auto;
  border-bottom: solid $border-width $main-border-color;
  padding-top: 10px;
  .gq-button {
    i {
      line-height: 0.8em;
    }
  }
  .gq-tabs {
    flex-grow: 1;
    justify-content: center;
    border-bottom: none;
  }
}

.day-divider-border {
  flex-grow: 1;
  border: 1px solid rgba(white, .3);
  margin: 0 16px;
}

.day-divider {
  height: 32px;
  @extend .row;
  align-items: center;
  .day-left-border {
    @extend .day-divider-border;
  }
  .day-right-border {
    @extend .day-divider-border;
  }
  .day-caption {
    font-size: 11px;
    color: $main-font-color;
  }
}

#loadMoreButton {
  cursor: pointer;
  text-align: center;
  color: $main-font-color;
  margin-bottom: 50px;
}

.gq-events-feed-filter-button {
  display: flex;
  align-items: center;
  color: rgba($main-font-color, .65);
  font-size: $event-menu-button-font-size;
  padding: 0.25em;
  cursor: pointer;
  &.selectedItem {
    color: $main-selection-color;
    border-bottom: solid $border-width $main-selection-color;
  }
}

#eventsList {
  flex-grow: 1;
  cursor: pointer;
  height: 100%;
}

.event-feed-item {
  @extend .col;
  height: 130px;
  max-height: 130px;
  justify-content: space-around;
  width: 100%;
  padding: 16px;
  font-size: $event-item-font-size;
  line-height: 1.31;
  color: rgba($main-font-color, .65);
  transition: all .2s linear;
  position: relative;

  .event-item-menu-icon {
    visibility: hidden;
    position: absolute;
    right: 5px;
  }

  &>div {
    pointer-events: none;
  }
  &.active {
    color: $main-font-color;
    background: rgba($main-font-color, .2);
    .event-feed-item-footer {
      color: $main-font-color;
    }
    .scores-container {
      background-color: transparent;
    }
  }
  &.hovered {
    .event-item-date-container {
      color: $main-font-color;
      font-weight: bold;
    }
    .event-item-source-name {
      color: rgba($main-font-color, .6);
    }
  }

  &:hover {
    background-color: rgba(white, .2);
    .scores-container {
      background-color: transparent;
    }

    .event-item-menu-icon {
      visibility: visible;
    }  
  }
  .event-feed-item-content {
    display: block;
    /* Fallback for non-webkit */
    display: -webkit-box;
    display: flex;
    max-width: 400px; // height: $event-item-font-size*$event-item-line-height*$event-item-lines-to-show; /* Fallback for non-webkit */
    -webkit-line-clamp: $event-item-lines-to-show;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .event-feed-item-footer {
    transition: all .2s linear;
    color: $event-footer-date-color;
    @extend .col;
    .event-item-source {
      align-items: center;
      justify-content: space-between;
      @extend .row;
      width: 100%;
    }
    .event-footer-details {
      @extend .row;
      align-items: center;
      font-size: 12px;
      width: 100%;
    }
    .event-item-date-container::after {
      content: '·';
      padding: 0 4px;
    }
    .event-item-dynamic-details {
      @extend .row;
      justify-content: space-between;
      margin-top: 8px;
      align-items: baseline;
    }
    .event-footer-left {
      @extend .row;
      width: 80%;
      .event-item-details {
        width: 105px;
        align-items: center;
      }
    }
    .event-item-flags {
      &.flexed {
        @extend .row;
        align-items: center;
        overflow: hidden;
      }
      padding-left: 5px;
    }
    .even-item-pending-status {
      border-radius: 3px;
      font-size: 8px;
      letter-spacing: .5px;
      padding: 4px;
      border: 1px solid $event-score-box-color;
      color: $event-score-box-color;
    }
    .has-human-scores {
      font-size: 20px;
      margin-left: 3px;
      opacity: 0.75;
      display: flex;
    }
    .event-item-scored-status {
      @extend .row;
      justify-content: center;
      align-items: center;
      width: auto;
      height: 14px;
      opacity: 0.31;
      font-family: ProximaNova;
      font-size: $event-item-scored-box-font-size;
      border: solid 1px rgb(226, 224, 237);
      border-radius: 3px;
      color: rgb(226, 224, 237);
      padding: 1px 2px;
    }
  }
  .scores-container {
    border-radius: 3px;
    pointer-events: all;
    background-color: $general-sub-container-bg;
    .score-summary {
      @extend .row;
      align-items: center;
      padding: 4px 8px;
      justify-content: space-between;
      >div {
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .score-dimension {
      font-size: 12;
      color: rgba($main-font-color, .65);
    }
    .score-color-container {
      border-radius: 10px;
      height: 8px;
      @extend .row;
      overflow: hidden;
    }
    * {
      pointer-events: none;
    }
  }
}

.impact-score {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  float: right;
}

.impact-tooltip-content {
  @extend .col;
  color: rgba($main-font-color, .65);
  padding: 12px;
  font-size: 12px;
  .impact-tooltip-row {
    @extend .row;
    align-items: center;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
    .impact-tooltip-col {
      margin-right: 12px;
      &:last-child {
        margin-right: 0;
        flex-grow: 1;
      }
    }
  }
}

.gq-events-feed-menu-buttons-search-placeholder {
  min-width: 32px;
  display: flex;
  align-items: center;
}

.gq-events-feed-search-bar {
  margin: -1px 23px 8px 18px;
  height: 0;
  overflow: hidden;
}

.gq-events-feed-search-bar-indicator {
  font-weight: 900;
  text-align: center;
  padding-top: 10px;
  display: flex;;
  align-items: center;
}

.gq-events-feed-search-bar-content {
  display: flex;
  align-content: center;
  background: #21374f;
  box-sizing: content-box;
  overflow: hidden;
  padding: 7px 8px;
  input {
    &::placeholder {
      color: rgba(#fff, .65);
          padding-top: 12px;
    }
    color: white;
    border: none;
    background: none;
    outline: none;
    font-size: 13px;
    flex-grow: 1;
  }
  i {
    padding-top: 20px;
    line-height: 1;
  }
}
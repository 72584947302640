.gq-ask-modal {
  max-width: 970px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  margin-right: -50%;
}

.gq-ask-header {
  font-size: 20px;
  font-weight: 300;
  color: #fff;
  margin-bottom: 67px;
  p {
    white-space: pre-wrap;
    max-width: 595px;
    margin: 0 5em 0 0;
  }
}

.gq-ask-content {
  border-bottom: 1px solid transparent;
  transition: border .5s ease;
  .gq-ask-input {
    font-size: 32px;
    outline: none;
    border: none;
    font-weight: 300;
    color: #fff;
    padding: 0;
    margin: 0;
    &::-webkit-input-placeholder {
      color: rgba(#fff, .4);
    }
    &:disabled {
      background: none;
      color: rgba(#fff, .4);
    }
  }
  &.active {
    border-bottom: 1px solid rgba(#fff, .15);
  }
}

.gq-ask-response {
  p {
    font-size: 32px;
    font-weight: 300;
    white-space: pre-wrap;
  }
}